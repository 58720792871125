import React from 'react';
import {AnchoredHorizontalTabs} from '@jetprivilege/web/dist/anchored-horizontal-tabs';
import popularflightCss from '../../static/css/home_page/popularFlights.module.css';
export default class PopularFlights extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            tabheaderData: [{ title: "Domestic", id: 0 }, { title: "International", id: 1 }],
            selectedTabIndex: 0
        }
    }
    componentDidMount = () => {
        if (this.props.isInternational) {
            this.setState({selectedTabIndex: 1})
        }
    }
    render() {
        let Tabs = (
            this.props.domesticFlights?.sectionHeading &&  this.props.internationalFlights?.sectionHeading ? 
            <div className='homepopularflighttabrow'>
                <AnchoredHorizontalTabs
                    selectedTabIndex={this.state.selectedTabIndex}
                    onChange={(e, selectedTabValue) => {
                        this.setState({selectedTabIndex: selectedTabValue})
                    }}
                    tabHeader={this.state.tabheaderData}
                />
            </div> : null
        )
        let renderingArray = this.state.selectedTabIndex == 0 ? 'domesticFlights' : 'internationalFlights';
        return (
            this.props.domesticFlights?.Links.length > 0 && this.props.internationalFlights?.Links.length > 0 ?
            <div className={popularflightCss.homepopularflightsection}>
             <div className={popularflightCss.homepopularflighrcontainer}>
             <div className={popularflightCss.homepopularflightrow}>
                <h2>Popular Flight Routes</h2>
                {Tabs}
                <div className={popularflightCss.hometopflighttabrow}>
                {
                    this.props[renderingArray]?.Links?.map((flight, index) => {
                        return (
                            <div 
                                className={popularflightCss.innerflightcolum} 
                                onClick={() => window.open(flight.linkURL, flight.newWindow)} 
                                key={`${renderingArray}_${index}`}
                            >
                                <strong>{flight.linkText}</strong>
                            </div>
                        )
                    })
                }
                </div>
                {
                    (this.props[renderingArray]?.CTAText
                    && this.props[renderingArray]?.CTAText != "") 
                    &&  <div className={popularflightCss.flightviewallrow}>
                            <a target={this.props[renderingArray]?.newWindowCTA}
                               href={this.props[renderingArray]?.CTAURL}>
                                {this.props[renderingArray]?.CTAText}
                                <img data-src="static/img/viewall.svg" alt="" alt-text="" class="lazyload"/>
                            </a>
                        </div>
                }
            </div>
        </div>
        </div> : null
        )
    }
}