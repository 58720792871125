import React from 'react';
import {Accordion, SingleExpandAccordion} from '@jetprivilege/web/dist/accordion';
import FAQCss from '../../static/css/home_page/FAQ.module.css';
import {decodeHTML} from './commonFunction';
const FAQ = (props) => {
    return (
        props.FAQData?.AccordionContainer[0]?.Accordion?.length > 0 ?
        <div className={FAQCss.homeaccordingsection}>
            <div className={FAQCss.homeaccordingcontiner}>
                <div className={FAQCss.homeaccordingrow}>
                    <h2>{props.FAQData?.AccordionContainer[0]?.Headline}</h2>
                    <div className={FAQCss.homeaccordingfullrow}>
                    <SingleExpandAccordion>
                    {
                         props.FAQData?.AccordionContainer[0]?.Accordion?.map((FAQEl, index) => {
                            return (
                                <div className={`homeleftaccordingrow ${index == props.FAQData?.AccordionContainer[0]?.Accordion.length - 1 && props.isMobile ? 'lasthomeleftmarginnone' : ''}`} key={`FAQ_${index}`} id={index}>
                                    <Accordion title={<h3>{FAQEl.contentHeadline}</h3>} id={index + 1}>    
                                        {
                                            FAQEl.textFormat == "text" ?
                                            <div className={FAQCss.homefaqdiffrentsection}>
                                                {FAQEl.textContent}
                                            </div>: 
                                            <div className={FAQCss.homefaqdiffrentsection} dangerouslySetInnerHTML={{__html:decodeHTML(FAQEl.richTextContent, props.ssr)}}>
                                            </div>
                                        }
                                    </Accordion>    
                                </div>
                            )
                        })
                    }
                    </SingleExpandAccordion>
                    <div className={FAQCss.homerightaccordingimg}><img data-src={`${props.imageDomainUrl}${props.FAQData?.AccordionContainer[0].webImage}`} alt={props.FAQData?.AccordionContainer[0].imageAltText} alt-text={props.FAQData?.AccordionContainer[0].imageAltText} class="lazyload"/></div> 
                </div>
            </div>
        </div>
    </div> : null
    )
}
export default React.memo(FAQ);