import Head from "next/head";
import { CTEvent } from "../actions/clevertap";
import { checkSession } from "../actions/auth.service";
import  config  from "../config/config.json";
import React from 'react';
class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {
        // let data = await checkSession();
        CTEvent.setGlobal();
    }

    // componentWillUnmount() {
    //     if (this.timeoutId) {
    //         clearTimeout(this.timeoutId);
    //     }
    // }

    // handleToggleMenu() {
    //     this.setState({
    //         isMenuVisible: !this.state.isMenuVisible
    //     })
    // }

    render() {
        return (
            <>
                <Head>
                <title>{this.props.title}</title>
                {
                    this.props.meta.map((value,index)=>{
                        return(
                                <meta key ={index} name={value.name} content={value.content}></meta>
                        )
                    })
                }
                <link rel="canonical" href={`https://www.intermiles.com/${!this.props.isInternational ? 'flights': 'international-flights'}`} />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content={this.props.keywordContent} />
                {
                    this.props.markupFAQ && this.props.markupFAQ != null && 
                    <script
                        key={`markupFAQ`}
                        type='application/ld+json'
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(this.props.markupFAQ,  null, "\t") }}
                    />
                }
                {
                    this.props.isHomePage && 
                    <>
                        <meta name="twitter:card" content="https://www.intermiles.com"/>
                        <meta name="twitter:site" content="@Intermiles"/>
                        <meta name="twitter:title" content="JetPrivilege Is Now InterMiles - Travel &amp; Lifestyle Rewards Program | InterMiles" />
                        <meta name="twitter:image:src" content="https://resources.intermiles.com/iwov-resources/images/FacebookMetaImages/JP-Logo_200x200px.jpg"/>
                        <meta name="twitter:description" content={this.props.metaContent}/>
                        <meta property="og:title" content="JetPrivilege Is Now InterMiles - Travel &amp; Lifestyle Rewards Program | InterMiles"/>
                        <meta property="og:url" content="https://www.intermiles.com"/>
                        <meta property="og:type" content="website"/>
                        <meta property="og:description" content={this.props.metaContent}/>
                        <meta property="og:image" content="https://resources.intermiles.com/iwov-resources/images/FacebookMetaImages/JP-Logo_200x200px.jpg"/>
                        <meta property="og:image:width" content="200"/>
                        <meta property="og:image:height" content="200"/>
                        <meta name="og:image:width" content="200"/>
                        <meta name="og:image:height" content="200"/>
                        <meta property="og:site_name" content="InterMiles"/>
                        <meta name="description" content={this.props.metaContent}/>
                    </>
                }
                </Head>
                <div id="header" className="jp-react-cmp" style={this.props.hideHeader ? {display: "none"} : {}}>
                </div>
                <div id="root" className={`jp-react-cmp ${this.props.somethingWentWrong ? 'modal-oops-wrong' : ''}`}>
                </div>
                <div className="jp-page-wrap">
                    {this.props.children}
                </div>
                <div id="footer" className="jp-react-cmp" style={this.props.hideHeader ? {display: "none"} : {}}>
                </div>
            </>
        )
    }
}


export default Layout
