import React from 'react';
import { ThemeProvider, InterMilesTheme } from '@jetprivilege/web/dist/theme';
import SearchWidget from './searchWidget';
import SearchWidgetMob from './searchWidgetMobile';
import About from './about';
import FAQ from './FAQ';
import PopularFlights from './popularFlights';
import LoginSection from './loginSection';
import WhyBookWithIM from './whyBookWithIM';
import Layout from '../Layout';
import config from '../../config/config.json';
import UserService from '../../services/user';
import Offers from './offers';
import Features from './feature';
import Guideline from './guideline';
import SEOFlightRoute from './SEOFlightRoutes';
// import { service } from "../../actions/middleware";
// import homePageData from "../../homepage.json";
// import homePageRedeemData from "../../homepageredeem.json";
// import { localStorageUtils } from '../../actions/common';
import BreadCrumb from '../Breadcrumb/BreadCrumb';
import { Adobe } from '../../actions/adobeLayer';
const moment = require('moment');
import ResumeBooking from '../../components/home_page/resumeBooking';
import ResumeBookingMobile from '../../components/home_page/resumeBookingMobile';
import {
  deleteResumeBookingCookiesServices,
  loadReviewPage,
} from './commonFunction';
// import {setSessionId} from "../../actions/common";
import { format } from 'date-fns';
export default class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageInfo: {},
      cookieStatus: true,
    };
  }
  componentDidMount = async () => {
    await this.setState({
      pageInfo: {
        category: 'flights',
        pageName: `flight home${
          this.props.isInternational ? ' - international' : ''
        }`,
        subCategory1: 'home',
        subCategory2: '',
        subCategory3: '',
        milesCategory: 'none',
        property: 'Flights',
      },
    });
    setTimeout(() => {
      Adobe.pageLoad(
        'page load',
        'HOME',
        this.state.pageInfo,
        this.props.loggedIn_Info
      );
    }, 2000);
    this.checkResumeBookValidation(this.props.resumeBookCookie);
    // await setSessionId(window);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevState.searchType !== this.state.searchType) {
      await this.setState({
        pageInfo: {
          category: 'flights',
          pageName: `flight home${
            this.props.isInternational ? ' - international' : ''
          }`,
          subCategory1: 'home',
          subCategory2: '',
          subCategory3: '',
          milesCategory: 'none',
          property: 'Flights',
        },
      });
    }
  };

  updateSearchType = (tabIndex) => {
    this.setState({ searchType: tabIndex == 0 ? 'UBA' : 'UBR' });
  };
  checkResumeBookValidation(cookieVal) {
    let cookieValue = cookieVal;
    if (cookieValue.length > 0) {
      let travelDate = cookieValue[0].onwardDate;
      let deptTime = cookieValue[0].onwardDeptTime;
      let timefilter = cookieValue[0].timefilter;
      let flightType = cookieValue[0].flightType;
      let flightConfigTime =
        flightType === 'International'
          ? timefilter.International
          : timefilter.Domestic;
      let travelDateTime = travelDate + ' ' + deptTime;
      if (
        moment
          .duration(
            moment(travelDateTime, 'YYYY-MM-DD HH:mm').diff(moment(new Date()))
          )
          .asHours() <= flightConfigTime
      ) {
        this.deleteResumeBookingCookies();
      }
    }
  }
  deleteResumeBookingCookies = () => {
    this.setState({
      cookieStatus: false,
    });
    deleteResumeBookingCookiesServices();
  };
  dateFormatResumeBooking = (ond, rnd) => {
    if (ond && rnd && ond != '' && rnd != '') {
      if (new Date(ond).getFullYear() == new Date(rnd).getFullYear()) {
        return `${format(
          new Date(ond),
          `${!this.props.isMobile ? 'eee, ' : ''}d MMM`
        )} - ${format(
          new Date(rnd),
          `${!this.props.isMobile ? 'eee, ' : ''}d MMM yyyy`
        )}`;
      }
      return `${format(
        new Date(ond),
        `${!this.props.isMobile ? 'eee, ' : ''}d MMM yyyy`
      )} - ${format(
        new Date(rnd),
        `${!this.props.isMobile ? 'eee, ' : ''}d MMM yyyy`
      )}`;
    } else {
      return format(
        new Date(ond),
        `${!this.props.isMobile ? 'eee, ' : ''}d MMM yyyy`
      );
    }
  };
  render() {
    let BookWithInterMiles =
      config.landingPageJSON.tierDependency.bookWithInterMiles.includes(
        this.props.userTier
      );
    let resumeBook = !this.props.isMobile ? (
      <ResumeBooking
        cookieData={this.props.resumeBookCookie[0]}
        deleteResumeBookingCookies={this.deleteResumeBookingCookies}
        loadReviewPage={loadReviewPage}
        pageInfo={this.state.pageInfo}
        loggedIn_Info={this.props.loggedIn_Info}
        dateFormatResumeBooking={this.dateFormatResumeBooking}
      />
    ) : (
      <ResumeBookingMobile
        cookieData={this.props.resumeBookCookie[0]}
        deleteResumeBookingCookies={this.deleteResumeBookingCookies}
        loadReviewPage={loadReviewPage}
        pageInfo={this.state.pageInfo}
        loggedIn_Info={this.props.loggedIn_Info}
        dateFormatResumeBooking={this.dateFormatResumeBooking}
      />
    );
    let EarnResumeBooking = this.props.searchType == 'UBA' ? resumeBook : null;
    let renderHTML = {
      searchWidget: !this.props.isMobile ? (
        <SearchWidget
          updateSearchType={this.updateSearchType}
          imageDomainUrl={this.props.imageDomainUrl}
          searchWidgetJSON={this.props.searchWidgetJSON}
          recentSearch={this.props.recentSearch}
          earnMilesRecentSearch={this.props.earnMilesRecentSearch}
          redeemMilesRecentSearch={this.props.redeemMilesRecentSearch}
          loggedIn_Info={this.props.loggedIn_Info}
          pageInfo={this.props.pageInfo}
          overlayHandling={this.props.overlayHandling}
          onwardFocus={this.props.onwardFocus}
          returnFocus={this.props.returnFocus}
          config={config}
          isInternational={this.props.isInternational}
          onSearchTypeToggle={this.props.onSearchTypeToggle}
          suggestedArray={this.props.suggestedArray}
          somethingWentWrong={this.props.somethingWentWrong}
        />
      ) : (
        <SearchWidgetMob
          updateSearchType={this.updateSearchType}
          imageDomainUrl={this.props.imageDomainUrl}
          searchWidgetJSON={this.props.searchWidgetJSON}
          recentSearch={this.props.recentSearch}
          earnMilesRecentSearch={this.props.earnMilesRecentSearch}
          redeemMilesRecentSearch={this.props.redeemMilesRecentSearch}
          isMobile={this.props.isMobile}
          loggedIn_Info={this.props.loggedIn_Info}
          pageInfo={this.props.pageInfo}
          isInternational={this.props.isInternational}
          config={config}
          onSearchTypeToggle={this.props.onSearchTypeToggle}
          suggestedArray={this.props.suggestedArray}
          somethingWentWrong={this.props.somethingWentWrong}
        />
      ),
      getStarted: (
        <Features
          searchWidgetJSON={this.props.searchWidgetJSON}
          featuresJSON={this.props.featuresJSON}
          imageDomainUrl={this.props.imageDomainUrl}
          searchType={this.props.searchType}
          earnMilesRecentSearch={this.props.earnMilesRecentSearch}
          redeemMilesRecentSearch={this.props.redeemMilesRecentSearch}
          userTier={this.props.userTier}
        />
      ),
      offerDetails: (
        <Offers
          offers={this.props.offers}
          didYouKnow={this.props.didYouknow}
          imageDomainUrl={this.props.imageDomainUrl}
          isMobApp={this.props.isMobApp}
          isMobile={this.props.isMobile}
          loggedIn_Info={this.props.loggedIn_Info}
          pageInfo={this.state.pageInfo}
          offerDetails={this.props.offerDetails}
          searchType={this.props.searchType}
        />
      ),
      genericInfo: (
        <Guideline
          guidelines={this.props.guidelines}
          imageDomainUrl={this.props.imageDomainUrl}
          downloadApp={this.props.downloadApp}
          pageInfo={this.state.pageInfo}
          loggedIn_Info={this.props.loggedIn_Info}
        />
      ),
      loginComponent: (
        <LoginSection
          loggedIn_Info={this.props.loggedIn_Info}
          loginComponentJSON={this.props.loginComponentJSON}
          config={config}
          imageDomainUrl={this.props.imageDomainUrl}
          userTier={this.props.userTier}
          pageInfo={this.state.pageInfo}
        />
      ),
      bookWithInterMiles: BookWithInterMiles ? (
        <WhyBookWithIM
          whyBookW_IM={this.props.whyBookW_IM}
          imageDomainUrl={this.props.imageDomainUrl}
        />
      ) : (
        ''
      ),
      popularRoutes: (
        <PopularFlights
          domesticFlights={this.props.DomesticFlight}
          internationalFlights={this.props.InternationalFlight}
          isInternational={this.props.isInternational}
        />
      ),
      aboutInterMiles: (
        <About
          aboutIM={this.props.aboutJSON}
          isMobile={this.props.isMobile}
          aboutFirstSection={this.props.aboutFirstSection}
          aboutSeconfSection={this.props.aboutSeconfSection}
          page={this.props.searchType}
          readMoreState={this.props.readMoreState}
          readMoreView={this.props.readMoreView}
          ssr={this.props.ssr}
        />
      ),
      faqTnC: (
        <FAQ
          FAQData={this.props.faqData}
          isMobile={this.props.isMobile}
          imageDomainUrl={this.props.imageDomainUrl}
          ssr={this.props.ssr}
        />
      ),
      genericRouteInfo: (
        <SEOFlightRoute
          seoSectionOne={this.props.seoSectionOne}
          seoSectionTwo={this.props.seoSectionTwo}
          seoSectionThree={this.props.seoSectionThree}
          SeoFlight={this.props.SeoFlight}
          isMobile={this.props.isMobile}
        />
      ),
      resumeBooking:
        this.props.resumeBookCookie.length > 0 &&
        this.state.cookieStatus &&
        this.props.loggedIn_Info.isLoggedIn
          ? EarnResumeBooking
          : null,
    };
    let HomePageParentClasses = ['homemainpagesection'];
    if (this.props.onwardFocus || this.props.returnFocus) {
      HomePageParentClasses.push('searchwidegtoverlay');
    }
    return (
      <div className={HomePageParentClasses.join(' ')}>
        {this.props.onwardFocus || this.props.returnFocus ? (
          <div className="searchoverlay"></div>
        ) : null}
        <ThemeProvider theme={InterMilesTheme}>
          <Layout
            title={config.page_seo.home.title}
            meta={config.page_seo.home.meta}
            markupFAQ={this.props.markupFAQ}
            metaContent={
              this.props.metaContent
                ? this.props.metaContent
                : config.page_seo.home.content.flights
            }
            isHomePage={true}
            keywordContent={
              this.props.keywordContent
                ? this.props.keywordContent
                : config.page_seo.home.keywordsContent.flights
            }
            isInternational={this.props.isInternational}
          >
            {
              <div className={`${InterMilesTheme} parent`}>
                <BreadCrumb breadCrumbData={this.props.breadCrumbData} />
                {this.props.componentPosition.map((html, index) => {
                  return (
                    <React.Fragment key={html} key={index}>
                      {renderHTML[html]}
                    </React.Fragment>
                  );
                })}
              </div>
            }
          </Layout>
        </ThemeProvider>
      </div>
    );
  }
}

export async function getServerSideProps({ req, query, params }) {
  var userdetails = await UserService.fetchUser(req.cookies);
  return {
    props: {
      loggedIn_Info: userdetails,
    },
  };
}
