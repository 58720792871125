import resumeBooking from "../../static/css/resumebooking.module.css";
import {ThemeProvider,InterMilesTheme} from '@jetprivilege/web/dist/theme';
// import { service } from "../../actions/middleware";
import PrimaryLoading from '../PrimaryLoading';
import config from '../../config/config.json';
import React from "react";
const moment = require('moment');
export default function ResumeBooking(props) {
	const cookieMinutes = (moment.duration((moment(new Date(), 'YYYY-MM-DD HH:mm')).diff(moment(new Date(props.cookieData.cookieCreationTime)))).asMinutes());
	const buttonVal = cookieMinutes > config.resumeBookingConfig.bookNowButtonTimeInMinute ? "Book Now" : "₹" + props.cookieData.payableAmount;
	let dateFormat = props.dateFormatResumeBooking(props.cookieData.onwardArrivalDate, props.cookieData.returnArrivalDate);
    return(
		<ThemeProvider theme={InterMilesTheme}>
  			<div className={`${InterMilesTheme} parent`}>
        		<div className={resumeBooking.resume_main}>
					<div className={resumeBooking.sub_resume}>
    					<div className={resumeBooking.resumecancelicon} onClick={props.deleteResumeBookingCookies}>
                    		<img src="/static/img/resume-ic-close.svg"></img>
                		</div>
						<div className={resumeBooking.resume_flex}>
						<div className={resumeBooking.resume_heading}>
							<h1>Resume Booking?</h1>
						</div>
						<div className={resumeBooking.resume_flight}>
							<div className={resumeBooking.resumerecentsearch}>
								
								<div className={resumeBooking.resumemaininnerrecentsearchrow}>
									<div className={resumeBooking.resumeinnerrecentsearch}>
										<span>{props.cookieData.origin}<b className={resumeBooking.resumerecentsearchcode}>{props.cookieData.originCode}</b></span>
										<span className={resumeBooking.resumeborderimg}><img alt="" src={(props.cookieData.journeyType === "One Way") ? "/static/img/flight-to-horizontal-desktop.svg" :"/static/img/flight-return.svg"}></img></span>
										<span>{props.cookieData.destination}<b className={resumeBooking.resumerecentsearchcode}>{props.cookieData.destinationCode}</b></span></div>
										<div className={[resumeBooking.resumeinnerrecentsearch, resumeBooking.resumedateinnerrecent].join(' ')}>
											<span>{props.cookieData.onwardAirlineCode} - {props.cookieData.onwardFlightNumber} </span>
											<span className={resumeBooking.resumedot}></span>
											{
												props.cookieData.returnAirlineCode != "" && props.cookieData.returnFlightNumber != "" ?
												<>
													<span>{props.cookieData.returnAirlineCode} - {props.cookieData.returnFlightNumber} </span>
													<span className={resumeBooking.resumedot}></span>
												</> : null
											}
											<span><img src="/static/img/calendar.svg" alt=""></img>{dateFormat}</span>
											<span className={resumeBooking.resumedot}></span>
											<span><img src="/static/img/ic-singleuser.svg" alt="" className={resumeBooking.passengercount}></img> {props.cookieData.totalTravellers} </span>
											<span className={resumeBooking.resumedot}></span>
											<span><img src="/static/img/seat-select-gray.svg" alt="" alt-text="" className={resumeBooking.passengercount}></img> {props.cookieData.cabinType}</span>
										</div>
									</div>
								</div>
							</div>
							<div className={resumeBooking.resume_button}>
								<div className="resumeprimary-btn">
								<PrimaryLoading 
									text={buttonVal}
									onClick={() => props.loadReviewPage(cookieMinutes, props)}>
									{buttonVal}
								</PrimaryLoading>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ThemeProvider>
    )
}