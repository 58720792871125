import React from 'react';
import {Button} from '@jetprivilege/web/dist/button';
export default class PrimaryLoading extends React.Component {
    timer;
  
    constructor(props) {
      super(props);
      this.state = {
        text: this.props.text,
        timer: 30,
        progress: 0,
      };
    }
  
    countDown() {
      if (this.state.timer > 0) {
        const value = this.state.timer - 1;
        this.setState({
          text: 'Redirecting...',
          timer: value,
          progress: 30 - value,
        });
      }
    }
  
    render() {
      return (
        <Button
          buttonType={this.props.mode}
          onClick={() => {
            this.props.onClick();
            if (!this.timer) {
              this.timer = setInterval(this.countDown.bind(this), 1000);
            }
          }}
          progress={this.state.progress / 30}
        >
          {this.state.text}
        </Button>
      );
    }
}