import React, { Component } from 'react';
import { ThemeProvider, InterMilesTheme } from '@jetprivilege/web/dist/theme';
import { VariantOfAutocomplete } from '@jetprivilege/web/dist/variant-of-autocomplete';
import { DateRangeCalendar } from '@jetprivilege/web/dist/date-range-calendar';
import { TravellerClass } from '@jetprivilege/web/dist/traveller-class';
import { Button } from '@jetprivilege/web/dist/button';
import { AnchoredHorizontalTabs } from '@jetprivilege/web/dist/anchored-horizontal-tabs';
import { newService, service } from '../../actions/middleware';
import { autosuggest } from '../../actions/apiendpointnames';
import { CTEvent } from '../../actions/clevertap';
// import { Branchio } from "../../actions/branchio";
import SearchWidgetCss from '../../static/css/home_page/searchWidget.module.css';
import Cookies from 'js-cookie';
import { localStorageUtils } from '../../actions/common';
import { Adobe } from '../../actions/adobeLayer';
import {
  addDays,
  format,
  differenceInDays,
  isSameDay,
  isAfter,
} from 'date-fns';
import config from '../../config/config.json';
export default class SearchWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      to: '',
      FromAirportData: '',
      ToAirportData: '',
      departureDate: '',
      returnDate: '',
      fromAirport: null,
      toAirport: null,
      datevisiblitystart: false,
      datevisiblityreturn: false,
      returndatemodal: false,
      fromvisibility: false,
      tovisibility: false,
      dateState: {
        calendarState: {
          startDate: addDays(new Date(), 1),
          endDate: addDays(new Date(), 1),
          valid: true,
        },
        startDate: {
          date: addDays(new Date(), 1),
          dateType: 'startDate',
        },
        endDate: undefined,
      },
      suggestedFromArray: this.props.suggestedArray,
      suggestedToArray: this.props.suggestedArray,
      showErrorTo: false,
      showErrorFrom: false,
      tabheaderData: config.isRedeemFlowActive
        ? [
            { title: 'Earn Miles', href: '', isDisabled: false },
            { title: 'Redeem Miles', href: '', isDisabled: false },
          ]
        : [{ title: 'Earn & Redeem Miles', href: '', isDisabled: false }],
      selectedTab: 0,
      showSearchWidget: false,
      infantnotabindex: 0,
      newName: null,
      newNameTo: null,
      minonwarddate: new Date(),
      maxonwarddate: new Date(
        new Date(new Date().setYear(new Date().getFullYear() + 1)).setDate(0)
      ),
      onward_placeholder: true,
      return_placeholder: true,
      fromblankerror: false,
      toblankerror: false,
      toautofocus: false,
      fromautofocus: false,
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat'],
      paxData: [
        {
          primaryText: 'Adult',
          initialValue: 1,
          minValue: 1,
          maxValue: 9,
        },
        {
          primaryText: 'Child',
          secondaryText: '(2-12 yrs)',
          initialValue: 0,
          minValue: 0,
          maxValue: 9,
        },
        {
          primaryText: 'Infant',
          secondaryText: '(Below 2 yrs)',
          initialValue: 0,
          minValue: 0,
          maxValue: 1,
        },
      ],
      travellerClass: 0,
      initialFocusOn: '',
      somethingWentWrong: this.props.somethingWentWrong,
      isRecentSearch: false,
      monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      travellerClassOpen: false,
      maxBookingDays: differenceInDays(
        new Date(
          new Date(new Date().setYear(new Date().getFullYear() + 1)).setDate(0)
        ),
        new Date()
      ),
      // returnMaxBookingDays: differenceInDays(new Date(new Date(new Date().setYear(new Date().getFullYear() + 1)).setDate(0)), addDays(new Date(), 1)) - differenceInDays(addDays(new Date(), 1), new Date()),
    };
    this.timer = null;
    this.myRef = React.createRef();
  }
  async componentDidMount() {
    await this.setState({
      selectedTab: window.location.search.includes('redeem') ? 1 : 0,
    });
  }
  onChangeFrom = (event) => {
    var requestedAirport = event.target.value;
    if (this.state.to == requestedAirport && requestedAirport != '') {
      this.setState({
        from: requestedAirport,
        showErrorFrom: true,
      });
    } else {
      this.setState({
        from: requestedAirport,
        showErrorFrom: false,
        showErrorTo: false,
      });
    }
    let prevData = this.state.suggestedFromArray.map((airport) => {
      return airport.ele;
    });
    // let payload = { city: requestedAirport, prevData: prevData };
    // var data = await service(payload, autosuggest);
    // Clear the timer
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      let newpayload = { query: requestedAirport, apiName: 'autosuggest' };
      newService(newpayload, 'autosuggest', '/glide/autoSuggest').then(
        (data) => {
          if (data.length) {
            const formateddata = this.formatdata(data);
            this.setState({
              suggestedFromArray: formateddata,
              somethingWentWrong: false,
            });
          } else {
            this.setState({
              suggestedFromArray: [],
              somethingWentWrong: true,
            });
          }
        }
      );
    }, 1000); // Adjust the throttle delay as needed (e.g., 1000ms = 1 second)
  };
  onSelectFrom = async (value) => {
    await this.state.suggestedFromArray.map((frm) => {
      if (frm.label == value) {
        this.setState((prevState) => ({
          fromElements: frm,
          fromvisibility: true,
          newName: (
            <>
              <span className={'search_city_name'}>{frm.cityName}</span>
            </>
          ),
          onward_placeholder: true,
          return_placeholder: true,
          fromblankerror: false,
          isRecentSearch: prevState.isRecentSearch
            ? !prevState.isRecentSearch
            : prevState.isRecentSearch,
        }));
      }
    });
    if (this.state?.toElements?.label == value && value != '') {
      this.setState({
        from: this.state.newName,
        showErrorFrom: true,
      });
    } else {
      this.setState({
        from: this.state.newName,
        showErrorFrom: false,
        showErrorTo: false,
      });
    }
  };
  getItemValue = (item) => {
    return item.label;
  };
  renderItemFrom = (item, highlighted) => {
    return (
      <div className="homesearchresultbox" key={item.id}>
        {/* <p>{item.heading}</p> */}
        {!!item.heading && item.heading.length > 0 ? (
          <small>{item.heading}</small>
        ) : null}
        <span key={item.id}></span>
        {item.showArrow ? (
          <strong>
            <img src="static/img/searcharrow.svg" alt="" alt-text="" />
          </strong>
        ) : null}
        <div className="homesearchinnerdatacolum">
          <span>
            {item.cityName} {item.countryName}
          </span>
          <span className="aircodecolum">{item.id}</span>
          <p>{item.airportName}</p>
        </div>
      </div>
    );
  };
  onChangeTo = (event) => {
    var requestedAirport = event.target.value;
    if (this.state.from == requestedAirport && requestedAirport != '') {
      this.setState({
        to: requestedAirport,
        showErrorTo: true,
      });
    } else {
      this.setState({
        to: requestedAirport,
        showErrorTo: false,
        showErrorFrom: false,
      });
    }
    let prevData = this.state.suggestedToArray.map((airport) => {
      return airport.ele;
    });
    // let payload = { city: requestedAirport, prevData: prevData };
    // var data = await service(payload, autosuggest);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let newpayload = { query: requestedAirport, apiName: 'autosuggest' };
      newService(newpayload, 'autosuggest', '/glide/autoSuggest').then(
        (data) => {
          if (data.length) {
            const formateddata = this.formatdata(data);
            this.setState({
              suggestedToArray: [...formateddata],
              somethingWentWrong: false,
            });
          } else {
            this.setState({
              suggestedToArray: [],
              somethingWentWrong: true,
            });
          }
        }
      );
    }, 1000); // Adjust the throttle delay as needed (e.g., 1000ms = 1 second)
  };
  onSelectTo = async (value) => {
    await this.state.suggestedToArray.map(async (to) => {
      if (to.label == value) {
        await this.setState((prevState) => ({
          toElements: to,
          tovisibility: true,
          newNameTo: (
            <>
              <span className={'search_city_name'}>{to.cityName}</span>
            </>
          ),
          onward_placeholder: true,
          return_placeholder: true,
          toblankerror: false,
          isRecentSearch: prevState.isRecentSearch
            ? !prevState.isRecentSearch
            : prevState.isRecentSearch,
        }));
      }
    });
    if (this.state?.fromElements?.label == value && value != '') {
      this.setState({
        to: this.state.newNameTo,
        showErrorTo: true,
      });
    } else {
      this.setState({
        to: this.state.newNameTo,
        showErrorTo: false,
        showErrorFrom: false,
      });
    }
  };
  shouldItemRender = (item, value2) => {
    return item;
  };
  onSubmitHandler = (type) => {
    const {
      toElements,
      fromElements,
      returnDate,
      paxData,
      travellerClass,
      dateState,
    } = this.state;
    // const obj = { to, from, departureDate, returnDate, toElements, fromElements };
    let fromparam, toparam;
    let flighttype;
    let fromObj = fromElements,
      toObj = toElements;

    fromparam = fromElements?.id;
    toparam = toElements?.id;
    flighttype = 'I';
    if (fromElements?.countryCode == 'IN' && toElements?.countryCode == 'IN') {
      flighttype = 'D';
    }
    if (this.state.showErrorTo == false && this.state.showErrorFrom == false) {
      let url = 'earn-airmiles';
      let searchType = 'UBA';
      if (this.state.selectedTab == 1) {
        url = 'redeem-airmiles';
        searchType = 'UBR';
      }
      let pageInfo = {
        category: 'flights',
        pageName: `flight home${
          this.props.isInternational ? ' - international' : ''
        }`,
        subCategory1: 'home',
        subCategory2: '',
        subCategory3: this.state.selectedTab == 1 ? 'redeem' : 'earn',
        milesCategory: this.state.selectedTab == 1 ? 'Redeem' : 'Earn',
        property: 'Flights',
      };
      let returnJourneyDate = '';
      if (dateState.endDate) {
        returnJourneyDate = dateState.endDate?.date;
      }
      let modifysearchObj = {
        labelId:
          this.state.selectedTab == 1 ? 'redeem-now:fly' : 'earn-now:fly',
        departureDate: dateState.startDate.date ? dateState.startDate.date : '',
        arrivalDate: returnJourneyDate,
        travelDestination: toparam ? toparam : '',
        travelOrigin: fromparam ? fromparam : '',
        pax:
          parseInt(paxData[0]['initialValue']) +
          ':' +
          parseInt(paxData[1]['initialValue']) +
          ':' +
          parseInt(paxData[2]['initialValue']),
        cabinType: travellerClass == 0 ? 'Economy' : 'Business',
        journeyType: returnDate ? 'Return' : 'One Way',
        flightType: flighttype == 'I' ? 'International' : 'Domestic',
        totalTravellers:
          parseInt(paxData[0]['initialValue']) +
          parseInt(paxData[1]['initialValue']) +
          parseInt(paxData[2]['initialValue']),
        pageInfo: pageInfo,
        loggedIn_Info: this.props.loggedIn_Info,
        page: 'HOME',
        searchResultType: 'original',
      };

      if (fromparam && toparam && fromparam != toparam) {
        CTEvent.callSearch({
          action:
            window.location.href.indexOf('/search') == -1
              ? 'Original Search'
              : 'Modify Search',
          ocode: fromObj?.id,
          dcode: toObj?.id,
          odesc: fromObj?.cityName,
          ddesc: toObj?.cityName,
          oairport: fromObj
            ? fromObj?.AIRLINE_NAME || fromObj?.airportName
            : '',
          dairport: toObj ? toObj?.airportName || toObj?.AIRLINE_NAME : '',
          rdate: returnJourneyDate,
          odate: new Date(dateState.startDate.date),
          class: travellerClass == 1 ? 'Business' : 'Economy',
          jtype: returnDate ? 'Return' : 'One Way',
          ftype: flighttype == 'I' ? 'International' : 'Domestic',
          ostype: 'Suggested',
          dstype: 'Suggested',
          adult: paxData[0].initialValue,
          child: paxData[1].initialValue,
          infant: paxData[2].initialValue,
          cat: this.state.selectedTab == 1 ? 'Redeem' : 'Earn',
        });

        // Branchio.callSearch({
        //   action: type == "recent" ? "Recent Search" : "Original Search", ocode: fromObj?.id, dcode: toObj?.id, odesc: fromObj?.cityName, ddesc: toObj?.cityName, oairport: fromObj ? (fromObj?.AIRLINE_NAME || fromObj?.airportName) : "", dairport: toObj? (toObj?.airportName || toObj?.AIRLINE_NAME) : "", rdate: returnJourneyDate, odate: new Date(dateState.startDate.date),
        //   class: travellerClass == 0 ? "Economy" : "Business", jtype: returnDate ? 'Return' : 'One Way', ftype: flighttype == "I" ? "International" : "Domestic", ostype: "Suggested", dstype: "Suggested", adult:  paxData[0].initialValue, child:  paxData[1].initialValue, infant:  paxData[2].initialValue, cat: this.state.selectedTab == 1 ? "Redeem" : "Earn"
        // });
        // Adobe.modifySearch(modifysearchObj);
        sessionStorage.setItem('home', true);
        // if (!this.state.isRecentSearch) {
        let recentSearchesObj = [];
        // Move recent searches from cookie to local storage. And Delete Previous Cookies
        let recentSearchCookies = Cookies.get('home_page_search');
        if (!!recentSearchCookies) {
          Cookies.remove('home_page_search', { path: '' });
        }
        let recentSearchData = localStorageUtils.getItem('home_page_search');
        if (!!recentSearchData) {
          recentSearchesObj = decodeURI(recentSearchData);
          recentSearchesObj = JSON.parse(decodeURI(recentSearchesObj));
        }
        let fromCity = fromObj.ele ? fromObj.ele : fromObj;
        let toCity = toObj.ele ? toObj.ele : toObj;
        fromCity['id'] = fromObj.id;
        fromCity['label'] = fromObj.label;
        fromCity['type'] = 'recent';
        toCity['id'] = toObj.id;
        toCity['label'] = toObj.label;
        toCity['type'] = 'recent';

        // let departureDate = obj.departureDate;
        // let returnDate = obj.returnDate ? obj.returnDate : "";

        let travellers = {};
        paxData.forEach((element) => {
          travellers[element.primaryText.toLowerCase()] = element.initialValue;
        });
        travellers['cabin'] = ['Economy', 'Business'];
        travellers['cabinclass'] = travellerClass;
        let newSearch = {
          fromCity: fromCity,
          toCity: toCity,
          traveller: travellers,
          departureDate: dateState.startDate.date,
          returnDate: returnJourneyDate,
          searchType: searchType,
        };
        let recentSearchIndex = recentSearchesObj.findIndex(
          (e) =>
            e.fromCity.id == newSearch.fromCity.id &&
            e.toCity.id == newSearch.toCity.id &&
            e.traveller.adult == newSearch.traveller.adult &&
            e.traveller.child == newSearch.traveller.child &&
            e.traveller.infant == newSearch.traveller.infant &&
            e.traveller.cabinclass == newSearch.traveller.cabinclass &&
            JSON.stringify(new Date(e.departureDate)).slice(1, 11) ==
              JSON.stringify(new Date(newSearch.departureDate)).slice(1, 11) &&
            JSON.stringify(new Date(e.returnDate)).slice(1, 11) ==
              JSON.stringify(new Date(newSearch.returnDate)).slice(1, 11) &&
            e.searchType == newSearch.searchType
        );
        if (recentSearchIndex == -1) {
          recentSearchesObj.push(newSearch);
          // Store only latest two searches
          if (recentSearchesObj.length > 2) recentSearchesObj.shift();
          // Set Cookie
          localStorageUtils.setItem(
            'home_page_search',
            encodeURI(JSON.stringify(recentSearchesObj))
          );
        }
        // }
        setTimeout(() => {
          window.location.href =
            `${this.props.config.landingPageJSON.hostUrl}/` +
            url +
            '/search?origin=' +
            fromparam +
            '&destination=' +
            toparam +
            '&cabin=' +
            travellerClass +
            '&adults=' +
            paxData[0].initialValue +
            '&childs=' +
            paxData[1].initialValue +
            '&infants=' +
            paxData[2].initialValue +
            '&onwarddt=' +
            this.convert(dateState.startDate.date) +
            '&returndt=' +
            this.convert(returnJourneyDate);
        }, 700);
      } else {
        if (
          !this.state?.fromElements?.label ||
          !this.state?.toElements?.label
        ) {
          if (!this.state?.fromElements?.label) {
            this.setState({
              fromblankerror: true,
            });
          }
          if (!this.state?.toElements?.label) {
            this.setState({
              toblankerror: true,
            });
          }
        }
      }
    }
  };
  convert = (str) => {
    if (!!str) {
      var date = str;
      if (typeof str == 'string') {
        date = new Date(date);
      }
      let mnth = ('0' + (date.getMonth() + 1)).slice(-2);
      let day = ('0' + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join('-');
    } else {
      return '';
    }
  };
  convertforinbox = (str, isRecent) => {
    if (!!str) {
      var date = typeof str == 'string' ? new Date(str) : str;
      if (isRecent) {
        return `${date.getDate()} ${
          this.state.monthNames[date.getMonth()]
        } ${date.getFullYear()}`;
      }
      return (
        <div>
          <div>
            {date.getDate()} {this.state.monthNames[date.getMonth()]}{' '}
            {date.getFullYear()}
          </div>
        </div>
      );
    } else {
      return '';
    }
  };
  swapOriginDestination = () => {
    this.setState((prevState) => ({
      to: typeof this.state.from == 'string' ? '' : this.state.from,
      from: typeof this.state.to == 'string' ? '' : this.state.to,
      ToAirPortData: this.state.FromAirportData,
      FromAirportData: this.state.ToAirPortData,
      fromElements: this.state.toElements,
      toElements: this.state.fromElements,
      fromvisibility: !!this.state.to,
      tovisibility: !!this.state.from,
      fromautofocus: false,
      toautofocus: false,
      isRecentSearch: prevState.isRecentSearch
        ? !prevState.isRecentSearch
        : prevState.isRecentSearch,
    }));
  };
  formatdata = (rawdata) => {
    const data = rawdata;
    for (const [key, value] of Object.entries(data)) {
      let searchType =
        data[0].type == 'recent' ? 'RECENT SEARCHS' : 'SUGGESTIONS';
      if (key == 0) {
        data[0].heading =
          data[0].type == 'popular' ? 'POPULAR CITIES' : searchType;
        if (data[0].type == 'popular') break;
      } else if (value.type == 'popular') {
        data[key].heading = 'POPULAR CITIES';
        break;
      } else if (value.type == 'recent') {
        data[key].heading = 'RECENT SEARCHES';
      }
    }
    return data;
  };
  onRecentSearch = (searchData, isDirectRedirect) => {
    this.props.overlayHandling('onwardFocus', false);
    this.props.overlayHandling('returnFocus', false);
    let dateState = { ...this.state.dateState };
    if (searchData.departureDate != '') {
      dateState.calendarState.startDate = new Date(searchData.departureDate);
      dateState.calendarState.endDate = new Date(searchData.departureDate);
      dateState.startDate.date = new Date(searchData.departureDate);
    }
    if (searchData.returnDate != '' && searchData.returnDate) {
      dateState.calendarState.endDate = new Date(searchData.returnDate);
      dateState.endDate = {
        date: new Date(searchData.returnDate),
        dateType: 'endDate',
      };
    } else {
      dateState.calendarState.endDate = new Date(searchData.departureDate);
      dateState.endDate = undefined;
    }
    // travellerClass state update
    let paxData = [...this.state.paxData];
    // setting intial value
    paxData[0].initialValue = searchData.traveller
      ? searchData.traveller.adult
      : '1';
    paxData[1].initialValue = searchData.traveller
      ? searchData.traveller.child
      : '0';
    paxData[2].initialValue = searchData.traveller
      ? searchData.traveller.infant
      : '0';
    // setting maxValue
    // changes as part of GPD-433
    // let maxValue =
    //   9 - (+searchData.traveller.adult + +searchData.traveller.child);
    paxData[0].maxValue = 9 - +searchData.traveller.child;
    paxData[1].maxValue = 9 - +searchData.traveller.adult;
    paxData[2].maxValue = paxData[0].initialValue;
    this.setState(
      {
        from: (
          <span className={'search_city_name'}>
            {searchData.fromCity?.cityName}
          </span>
        ),
        fromElements: searchData.fromCity,
        to: (
          <span className={'search_city_name'}>
            {searchData.toCity?.cityName}
          </span>
        ),
        toElements: searchData.toCity,
        totaltravellers: {
          adults: searchData.traveller.adult,
          childrens: searchData.traveller.child,
          infant: searchData.traveller.infant,
          cabin: searchData.traveller.cabinclass,
        },
        paxData,
        travellerClass: searchData.traveller
          ? searchData.traveller.cabinclass
          : '0',
        tovisibility: true,
        fromvisibility: true,
        datevisiblityreturn: false,
        datevisiblitystart: false,
        isRecentSearch: true,
        showErrorFrom: false,
        showErrorTo: false,
        toblankerror: false,
        fromblankerror: false,
        dateState,
        // returnMaxBookingDays:differenceInDays(new Date(new Date(new Date().setYear(new Date().getFullYear() + 1)).setDate(0)),  dateState.calendarState.startDate) - differenceInDays(dateState.calendarState.startDate, this.state.minonwarddate)
      },
      () => {
        if (isDirectRedirect) {
          this.onSubmitHandler('recent');
        }
      }
    );
  };
  manageBookingRedirection = () => {
    Adobe.clickEvent(
      'flights home:manage booking',
      'home',
      this.props.pageInfo,
      this.props.loggedIn_Info
    );
    let url = `${this.props.config.landingPageJSON.hostUrl}/managebooking`;
    if (this.props.loggedIn_Info.isLoggedIn) {
      url = this.props.config.myPurchasesURL;
    }
    window.open(url, '_blank');
  };
  recentSearchRender = () => {
    let totalRecents = 0;
    this.props.recentSearch.map((recent) => {
      if (this.state.selectedTab == (recent.searchType == 'UBA' ? 0 : 1)) {
        totalRecents++;
      }
    });
    return this.props.recentSearch != null &&
      this.props.recentSearch.length > 0 &&
      totalRecents > 0 ? (
      <div className="recentsearchsection">
        <span>RECENT SEARCHES</span>
        <div className="recentsearchsectiondropdown">
          {this.props.recentSearch.map((recent, index) => {
            if (
              this.state.selectedTab == (recent.searchType == 'UBA' ? 0 : 1)
            ) {
              let month =
                new Date(recent.returnDate).getMonth() !=
                new Date(recent.departureDate).getMonth()
                  ? this.state.monthNames[
                      new Date(recent.departureDate).getMonth()
                    ]
                  : '';
              let travelClass =
                recent.traveller.cabinclass === 0 ||
                recent.traveller.cabinclass === '0'
                  ? 'Economy'
                  : 'Business';

              return (
                <div
                  onClick={() => this.onRecentSearch(recent)}
                  className="maininnerrecentsearchrow"
                  key={index}
                >
                  <div className="innerrecentsearchsectiondropdown">
                    <span>
                      {recent.fromCity.cityName}
                      <b>{recent.fromCity.cityCode}</b>
                    </span>
                    <span>
                      <img
                        src={
                          recent.returnDate != ''
                            ? '/static/img/round_recent.svg'
                            : '/static/img/oneway_recent.svg'
                        }
                        alt=""
                        alt-text=""
                      />
                    </span>
                    <span>
                      {recent.toCity.cityName}
                      <b>{recent.toCity.cityCode}</b>
                    </span>
                  </div>
                  <div className="innerrecentsearchsectiondropdown dateinnerrecentsearchsectiondropdown">
                    <span>
                      {recent.returnDate != ''
                        ? `${new Date(
                            recent.departureDate
                          ).getDate()} ${month} - ${this.convertforinbox(
                            recent.returnDate,
                            true
                          )}`
                        : this.convertforinbox(recent.departureDate, true)}
                    </span>
                    <span>.</span>
                    <span>{recent.traveller ? travelClass : 'Economy'}</span>
                    <span>.</span>
                    <span>
                      {recent.traveller
                        ? +recent.traveller.adult +
                          +recent.traveller.child +
                          +recent.traveller.infant
                        : '1'}
                      &nbsp; Passenger
                    </span>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    ) : null;
  };
  render() {
    let innerSearchWidgetClsArr = [];
    innerSearchWidgetClsArr.push(SearchWidgetCss.innerserchwidgetrow);
    if (
      this.props[
        this.state.selectedTab == 0
          ? 'earnMilesRecentSearch'
          : 'redeemMilesRecentSearch'
      ]?.length > 0
    ) {
      innerSearchWidgetClsArr.push(SearchWidgetCss.recentSearchMargin);
    }
    let recentSearchProp =
      this.state.selectedTab == 0
        ? 'earnMilesRecentSearch'
        : 'redeemMilesRecentSearch';
    let fromPlaceHolder =
      this.state.suggestedFromArray.length == 0 &&
      this.state.somethingWentWrong ? (
        <div>
          <div className="jp-no-result-found-text">
            Service unavailable. Please try again later.
          </div>
          <img src={'/static/img/service-failure.svg'} />
        </div>
      ) : (
        <div className="">
          <div className="jp-no-result-found-text">
            <b>Please enter Origin</b>
          </div>
        </div>
      );
    let toPlaceholder =
      this.state.suggestedToArray.length === 0 &&
      this.state.somethingWentWrong ? (
        <div>
          <div className="jp-no-result-found-text">
            Service unavailable. Please try again later.
          </div>
          <img src={'/static/img/service-failure.svg'} />
        </div>
      ) : (
        <div className="">
          <div className="jp-no-result-found-text">
            <b>Please enter destination</b>
          </div>
        </div>
      );
    return (
      <ThemeProvider theme={InterMilesTheme}>
        <div className={`${InterMilesTheme} parent`}>
          <div
            className="hometopbannersection"
            style={{
              background: `url("${this.props.imageDomainUrl}${this.props.searchWidgetJSON.staticBannerContent.Content[0].WebImage}")no-repeat`,
            }}
          >
            <h1>
              {
                this.props.searchWidgetJSON.staticBannerContent.Content[0]
                  .webHeading
              }
            </h1>
            {!!this.props.searchWidgetJSON.staticBannerContent.Content[0]
              .webSubHeading && (
              <p className="bannerpara">
                {
                  this.props.searchWidgetJSON.staticBannerContent.Content[0]
                    .webSubHeading
                }
              </p>
            )}
            <div className="homebannertabsection">
              <AnchoredHorizontalTabs
                selectedTabIndex={this.state.selectedTab}
                onChange={(e, selectedTabValue) => {
                  this.setState({ selectedTab: selectedTabValue });
                  this.props.updateSearchType(selectedTabValue);
                  this.props.onSearchTypeToggle(selectedTabValue);
                }}
                tabHeader={this.state.tabheaderData}
                additionalStyle={{ backgroundColor: 'transparent' }}
              />
              <div className={SearchWidgetCss.innerserchwidgtmanagerow}>
                <a
                  className={SearchWidgetCss.manageBookingLink}
                  onClick={this.manageBookingRedirection}
                >
                  Manage Booking
                  {/* <img src="/static/img/right_arrow.svg" alt="arrow"/> */}
                </a>
              </div>
            </div>
            <form>
              <div className={innerSearchWidgetClsArr.join(' ')}>
                <div className={SearchWidgetCss.innerserchwidgtcontainer}>
                  <div className={SearchWidgetCss.innerserchwidgtrow}>
                    <div className={SearchWidgetCss.innerserchwidgtfirstcolum}>
                      <div className="homesearchautocompletedropdown">
                        <div>
                          {this.state.fromvisibility ? (
                            <Button
                              additionalStyle={{
                                margin: '0 0px 10px 0',
                                float: 'left',
                                zIndex: '100',
                                backgroundColor: 'white',
                                color: 'black',
                                width: '100%',
                              }}
                              additionalClassName={['Z-index']}
                              buttonType=""
                              onClick={async (e) => {
                                this.setState({
                                  fromvisibility: !this.state.fromvisibility,
                                  fromElements: {},
                                  from: '',
                                  fromautofocus: true,
                                  FromAirportData: {},
                                });
                              }}
                            >
                              {/* this.state.startDates.startDate.split(''))} */}
                              <div className="jp-mobile-recently-searched">
                                <span className={'image_label'}>
                                  <img
                                    src="/static/img/onwordflaghome.svg"
                                    alt=""
                                    alt-text=""
                                  />
                                </span>
                                {this.state.from}
                              </div>
                            </Button>
                          ) : null}
                          {!this.state.fromvisibility ? (
                            <VariantOfAutocomplete
                              className="atou-testing"
                              additionalClassName="interMiles-autocomplete"
                              autoHighlight={false}
                              items={this.state.suggestedFromArray}
                              autoFocus={
                                !this.state.fromvisibility &&
                                this.state.fromautofocus
                              }
                              // inputIcon=""
                              getItemValue={this.getItemValue}
                              renderItem={this.renderItemFrom}
                              value={this.state.from}
                              shouldItemRender={this.shouldItemRender}
                              onChange={(e) => this.onChangeFrom(e)}
                              inputIcon={'/static/img/onwordflaghome.svg'}
                              onSelect={(e) => this.onSelectFrom(e)}
                              onFocus={(e) => {
                                this.setState({ onward_placeholder: false });
                              }}
                              onBlur={(e) => {
                                this.setState({ onward_placeholder: true });
                              }}
                              inputProps={{
                                placeholder: 'Where from?',
                                id: 'search-flyout-mobile-input',
                                className: `${
                                  this.state.setValue === ''
                                    ? 'jp-input-empty'
                                    : 'jp-input-value'
                                } jp-home-desktop-input ${
                                  this.state.somethingWentWrong &&
                                  this.props.onwardFocus
                                    ? 'serviceunavibleborder'
                                    : ''
                                }`,
                                // autoFocus: false
                              }}
                              onMenuVisibilityChange={(isOpen) => {
                                this.props.overlayHandling(
                                  'onwardFocus',
                                  isOpen
                                );
                              }}
                              renderMenu={(children) => (
                                <div
                                  className={`intermiles-homepage-search suggest ${
                                    this.state.suggestedFromArray.length === 0
                                      ? 'recent'
                                      : 'suggest'
                                  }`}
                                >
                                  <div className="intermiles-homepage-search-inner">
                                    {/* <div className='intermiles-title'>POPULAR CITIES</div> */}
                                    <div id="ex1-label" />
                                    <ul
                                      role="listbox"
                                      id="ex1-listbox"
                                      aria-labelledby="ex1-label"
                                      className={'ul-style'}
                                    >
                                      <div className="jp-mobile-recently-searched">
                                        {this.state.suggestedFromArray
                                          .length === 0 ? (
                                          fromPlaceHolder
                                        ) : (
                                          <>
                                            {/* {this.recentSearchRender()} */}
                                            {children}
                                          </>
                                        )}
                                      </div>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            />
                          ) : null}
                        </div>
                        {(this.state.showErrorFrom ||
                          this.state.fromblankerror) && (
                          <div className="errorbothrow">
                            <p>
                              {this.state.fromblankerror
                                ? 'Please enter the Origin'
                                : 'From and To cannot be same'}
                            </p>
                          </div>
                        )}
                      </div>
                      <span
                        className={`switcharrowrow homeswitchrow ${
                          this.props.onwardFocus ||
                          this.props.returnFocus ||
                          this.state.datevisiblitystart ||
                          this.state.datevisiblityreturn ||
                          this.state.travellerClassOpen
                            ? 'low_z_index'
                            : ''
                        }`}
                      >
                        <div
                          className="swapOriginDestination"
                          onClick={() => {
                            if (
                              this.state.from &&
                              this.state.to &&
                              typeof this.state.from != "string" &&
                              typeof this.state.to != "string"
                            ) {
                              this.swapOriginDestination();
                            }
                          }}
                        >
                          <img
                            src="/static/img/switchhome.svg"
                            alt=""
                            alt-text=""
                          />
                        </div>
                      </span>
                      <div className="homesearchautocompletedropdown">
                        {/* <label>To</label> */}
                        {this.state.tovisibility ? (
                          <Button
                            additionalStyle={{
                              margin: '0 0px 10px 0',
                              float: 'left',
                              zIndex: '100',
                              backgroundColor: 'white',
                              color: 'black',
                              width: '100%',
                            }}
                            additionalClassName={['Z-index']}
                            buttonType=""
                            onClick={async (e) => {
                              this.setState({
                                tovisibility: !this.state.tovisibility,
                                toElements: {},
                                to: '',
                                toautofocus: true,
                                ToAirPortData: {},
                              });
                            }}
                          >
                            {/* this.state.startDates.startDate.split(''))} */}
                            <div className="jp-mobile-recently-searched">
                              <span className={'image_label'}>
                                <img
                                  src="/static/img/returnflaghome.svg"
                                  alt=""
                                  alt-text=""
                                />
                              </span>
                              {this.state.to}
                            </div>
                          </Button>
                        ) : null}
                        {!this.state.tovisibility ? (
                          <VariantOfAutocomplete
                            className="atou-testing"
                            additionalClassName="interMiles-autocomplete"
                            autoHighlight={false}
                            autoFocus={
                              !this.state.tovisibility && this.state.toautofocus
                            }
                            items={this.state.suggestedToArray}
                            // inputIcon=""
                            getItemValue={this.getItemValue}
                            renderItem={this.renderItemFrom}
                            value={this.state.to}
                            shouldItemRender={this.shouldItemRender}
                            onChange={(e) => this.onChangeTo(e)}
                            onSelect={(e) => this.onSelectTo(e)}
                            onFocus={(e) => {
                              this.setState({ return_placeholder: false });
                            }}
                            onBlur={(e) => {
                              this.setState({ return_placeholder: true });
                            }}
                            inputProps={{
                              placeholder: 'Where to?',
                              id: 'search-flyout-mobile-input',
                              className: `${
                                this.state.setValue === ''
                                  ? 'jp-input-empty'
                                  : 'jp-input-value'
                              } jp-home-desktop-input ${
                                this.state.somethingWentWrong &&
                                this.props.returnFocus
                                  ? 'serviceunavibleborder'
                                  : ''
                              }`,
                              // autoFocus: false
                            }}
                            onMenuVisibilityChange={(isOpen) => {
                              this.props.overlayHandling('returnFocus', isOpen);
                            }}
                            inputIcon={'/static/img/returnflaghome.svg'}
                            renderMenu={(children) => (
                              <div
                                className={`intermiles-homepage-search suggest ${
                                  this.state.suggestedToArray.length === 0
                                    ? 'recent'
                                    : 'suggest'
                                }`}
                              >
                                <div className="intermiles-homepage-search-inner">
                                  {/* <div className='intermiles-title'>POPULAR CITIES</div> */}
                                  <div id="ex1-label" />
                                  <ul
                                    role="listbox"
                                    id="ex1-listbox"
                                    aria-labelledby="ex1-label"
                                    className={'ul-style'}
                                  >
                                    <div className="jp-mobile-recently-searched">
                                      {this.state.suggestedToArray.length ===
                                      0 ? (
                                        toPlaceholder
                                      ) : (
                                        <>
                                          {/* {this.recentSearchRender()} */}
                                          {children}
                                        </>
                                      )}
                                    </div>
                                  </ul>
                                </div>
                              </div>
                            )}
                          />
                        ) : (
                          ''
                        )}
                        {(this.state.showErrorTo ||
                          this.state.toblankerror) && (
                          <div className="errorbothrow">
                            <p>
                              {this.state.toblankerror
                                ? 'Please enter the Destination'
                                : 'From and To cannot be same'}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={SearchWidgetCss.innerserchwidgtsecondcolum}>
                      <div
                        className={`homesearchautocompletedropdown ${
                          this.state.datevisiblitystart ? 'active' : ''
                        }`}
                      >
                        <DateRangeCalendar
                          startDate={
                            this.state.dateState.calendarState.startDate
                          }
                          endDate={this.state.dateState.calendarState.endDate}
                          mode="singleDateRange"
                          additionalClassName={['date-range-positionRelative']}
                          additionalStyle={{
                            zIndex: '10',
                            position: 'absolute',
                          }}
                          currentSelection="startDate"
                          maxDate={this.state.maxonwarddate}
                          maxBookingDays={this.state.maxBookingDays}
                          isOverlay={true}
                          customInputView={
                            <Button
                              className="homedatebutton"
                              additionalStyle={{
                                margin: '0 0px 0px 0',
                                float: 'left',
                                zIndex: '100',
                                backgroundColor: 'white',
                                color: 'black',
                                width: '100%',
                              }}
                              additionalClassName={['Z-index']}
                              buttonType=""
                              onClick={(e) => {
                                this.setState({
                                  datevisiblitystart:
                                    !this.state.datevisiblitystart,
                                });
                              }}
                            >
                              <div
                                className={`startdate-inputhome ${
                                  this.state.datevisiblitystart
                                    ? 'datefromcalendar'
                                    : ''
                                }`}
                              >
                                <span>
                                  <img
                                    src="/static/img/calendar.svg"
                                    alt=""
                                    alt-text=""
                                  />
                                </span>
                                <div>
                                  <div>
                                    {format(
                                      this.state.dateState.startDate.date,
                                      'dd MMM yyyy'
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Button>
                          }
                          onSingleDateRangeSelectionChange={(result) => {
                            const newState = { ...this.state.dateState };
                            newState.calendarState = result.calendarState;
                            newState.startDate = {
                              date: result.calendarState.startDate,
                              dateType: result.dateType,
                            };
                            if (
                              this.state.dateState.endDate &&
                              (isAfter(
                                result.calendarState.startDate,
                                this.state.dateState.endDate.date
                              ) ||
                                !isSameDay(
                                  this.state.dateState.endDate.date,
                                  result.calendarState.endDate
                                ))
                            ) {
                              newState.endDate = undefined;
                            }
                            this.setState({
                              dateState: newState,
                              // returnMaxBookingDays:  differenceInDays(new Date(new Date(new Date().setYear(new Date().getFullYear() + 1)).setDate(0)), result.calendarState.startDate) - differenceInDays(result.calendarState.startDate, this.state.minonwarddate)
                            });
                          }}
                          onCalendarVisibilityChange={(isOpen) => {
                            this.setState({
                              datevisiblitystart: isOpen,
                            });
                          }}
                        />
                      </div>
                      <span
                        className={`switcharrowrow homeswitchrow flightdate ${
                          this.props.onwardFocus ||
                          this.props.returnFocus ||
                          this.state.datevisiblitystart ||
                          this.state.datevisiblityreturn ||
                          this.state.travellerClassOpen
                            ? 'low_z_index'
                            : ''
                        }`}
                      ></span>
                      <div
                        className={`homesearchautocompletedropdown returndatesec ${
                          this.state.datevisiblityreturn ? 'active' : ''
                        }`}
                      >
                        <DateRangeCalendar
                          startDate={
                            this.state.dateState.calendarState.startDate
                          }
                          endDate={this.state.dateState.calendarState.endDate}
                          mode="singleDateRange"
                          currentSelection="endDate"
                          flyoutPosition="center"
                          additionalClassName={[
                            'date-range-positionRelative',
                            '',
                          ]}
                          additionalStyle={{
                            zIndex: '10',
                            position: 'absolute',
                          }}
                          maxDate={this.state.maxonwarddate}
                          maxBookingDays={this.state.maxBookingDays}
                          minDate={this.state.minonwarddate}
                          isOverlay={true}
                          customInputView={
                            <Button
                              className="homedatebutton"
                              additionalStyle={{
                                margin: '0 0px 0px 0',
                                width: '100%',
                                float: 'left',
                                zIndex: '100',
                                backgroundColor: 'white',
                                color: 'black',
                              }}
                              additionalClassName={['Z-index']}
                              buttonType=""
                              onClick={() => {
                                this.setState({
                                  datevisiblityreturn:
                                    !this.state.datevisiblityreturn,
                                  returndatemodal: true,
                                });
                              }}
                            >
                              {this.state.dateState.endDate &&
                              this.state.dateState.endDate.date ? (
                                <div className="return-input-calenderhome">
                                  {' '}
                                  <div>
                                    <div>
                                      {format(
                                        this.state.dateState.endDate.date,
                                        'dd MMM yyyy'
                                      )}
                                    </div>
                                  </div>
                                  <img
                                    src={'/static/img/datehomeclose.svg'}
                                    alt=""
                                    alt-text=""
                                    onClick={(event) => {
                                      const newState = {
                                        ...this.state.dateState,
                                      };
                                      newState.calendarState = {
                                        ...newState.calendarState,
                                        endDate:
                                          newState.calendarState.startDate,
                                      };
                                      newState.endDate = undefined;
                                      this.setState({
                                        dateState: newState,
                                      });
                                      event.stopPropagation();
                                    }}
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className="book-roundhome">
                                    Return Date
                                  </div>
                                </>
                              )}
                            </Button>
                          }
                          onSingleDateRangeSelectionChange={(result) => {
                            const newState = { ...this.state.dateState };
                            newState.calendarState = result.calendarState;
                            newState.endDate = {
                              date: result.calendarState.endDate,
                              dateType: result.dateType,
                            };
                            if (
                              !isSameDay(
                                result.calendarState.startDate,
                                this.state.dateState.startDate.date
                              )
                            ) {
                              newState.startDate = { ...newState.endDate };
                            }
                            this.setState({
                              dateState: newState,
                            });
                          }}
                          onCalendarVisibilityChange={(isOpen) => {
                            this.setState({ datevisiblityreturn: isOpen });
                          }}
                        />
                      </div>
                    </div>
                    <div className={SearchWidgetCss.innerserchwidgtthirdcolum}>
                      <div
                        className={
                          SearchWidgetCss.hometravellersearchautocompletedropdown
                        }
                      >
                        <TravellerClass
                          passengerData={this.state.paxData}
                          inputLabel=" "
                          onPickerVisibilityChange={(isOpen) =>
                            this.setState({ travellerClassOpen: isOpen })
                          }
                          customInputView={
                            <div
                              className={`${
                                SearchWidgetCss.travellerClasstoprow
                              } ${
                                this.state.travellerClassOpen
                                  ? SearchWidgetCss.active
                                  : ''
                              }`}
                            >
                              <div
                                className={
                                  SearchWidgetCss.travellerClasstopleftcolum
                                }
                              >
                                <img
                                  src="/static/img/ic-singleuser.svg"
                                  alt=""
                                  alt-text=""
                                />
                                <span>
                                  {this.state.paxData[0].initialValue +
                                    this.state.paxData[1].initialValue +
                                    this.state.paxData[2].initialValue}
                                </span>
                              </div>
                              <div
                                className={
                                  SearchWidgetCss.travellerClasstoprightcolum
                                }
                              >
                                <img
                                  src="/static/img/seat-select-gray.svg"
                                  alt=""
                                  alt-text=""
                                />
                                <span>
                                  {this.state.travellerClass == 0
                                    ? 'Economy'
                                    : 'Business'}
                                </span>
                              </div>
                              <div></div>
                            </div>
                          }
                          isOverlay={true}
                          selectedClassIndex={this.state.travellerClass}
                          onClassItemClick={(index, item) =>
                            this.setState((prevState) => ({
                              travellerClass: index,
                              isRecentSearch: prevState.isRecentSearch
                                ? !prevState.isRecentSearch
                                : prevState.isRecentSearch,
                            }))
                          }
                          onPassengerSelectionChange={(data) =>
                            this.setState((prevState) => ({
                              paxData: data,
                              isRecentSearch: prevState.isRecentSearch
                                ? !prevState.isRecentSearch
                                : prevState.isRecentSearch,
                            }))
                          }
                        />
                      </div>
                    </div>
                    <div className={SearchWidgetCss.homeapplysearchrow}>
                      <Button
                        buttonType="primary"
                        className={SearchWidgetCss.homeapplysearchbutton}
                        onClick={() => {
                          this.onSubmitHandler('original');
                        }}
                      >
                        <img
                          src="/static/img/searchapplybutt.svg"
                          alt=""
                          alt-text=""
                        />
                      </Button>
                    </div>
                    {this.props[recentSearchProp]?.length > 0 ? (
                      <div className={SearchWidgetCss.homerecentsearchsetion}>
                        <strong>Recent Searches: </strong>
                        <span>
                          {this.props[recentSearchProp]?.map(
                            (search, index) => {
                              let month =
                                new Date(search.returnDate).getMonth() !=
                                new Date(search.departureDate).getMonth()
                                  ? this.state.monthNames[
                                      new Date(search.departureDate).getMonth()
                                    ]
                                  : '';
                              return (
                                <small
                                  onClick={() =>
                                    this.onRecentSearch(search, false)
                                  }
                                  style={{ cursor: 'pointer' }}
                                  key={index}
                                >
                                  <span>{search.fromCity.cityName}</span>
                                  <span
                                    className={
                                      SearchWidgetCss.homerecentsearchdivider
                                    }
                                  >
                                    {' '}
                                    -{' '}
                                  </span>
                                  <span>{search.toCity.cityName}</span>
                                  <span
                                    className={SearchWidgetCss.homedatedivider}
                                  >
                                    |
                                  </span>
                                  <span>
                                    {search.returnDate != ''
                                      ? `${new Date(
                                          search.departureDate
                                        ).getDate()} ${month} - ${this.convertforinbox(
                                          search.returnDate,
                                          true
                                        )}`
                                      : this.convertforinbox(
                                          search.departureDate,
                                          true
                                        )}
                                  </span>
                                </small>
                              );
                            }
                          )}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
