import React from 'react'
import guidelineCss from '../../static/css/home_page/guideline.module.css';
import { Adobe } from '../../actions/adobeLayer'
export default class Guideline extends React.PureComponent{
    constructor(props) {
        super(props);
    }
    hyperLink = (guideline) => (
        <span onClick={() => {
                window.open(guideline?.benefitsContainer?.link, '_blank');
                if (guideline?.benefitsContainer?.ctaText === "Download the app now") {
                    Adobe.clickEvent("download now","home",this.props.pageInfo,this.props.loggedIn_Info);   
                }
                // guideline?.benefitsContainer?.ctaText === "Download the app now"? Adobe.clickEvent("download now","home",this.props.pageInfo,this.props.loggedIn_Info) : null
            }}>
            <strong>{guideline?.benefitsContainer?.ctaText}</strong>
            <span><img data-src={`${this.props.imageDomainUrl}${guideline.benefitsContainer.image}`} alt="" alt-text="" class="lazyload"/></span>
        </span>
    )
    render() {
        return (
            this.props.guidelines?.Items?.length > 0 ?
            <div className={this.props.guidelines?.Items?.length == 1 && this.props.downloadApp == null ? 'single_guidelinerow' : 'two_guidelinerow'}>
            <div className={guidelineCss.homegiudelinescontainar}>
                {
                    this.props.guidelines?.Items?.map((guideline, index) => {
                        return (
                            
                            <div key={index} className={this.props.guidelines?.Items?.length == 1 ? 'single_guideline' : 'two_guideline'}>
                                <div className={guidelineCss.homegiudelinesleftcolum}>
                                    <img data-src={`${this.props.imageDomainUrl}${guideline.benefitsContainer.WebImage}`} alt="" alt-text="" class="lazyload"/>
                                </div>
                                <div className='homegiudelinesrightcolum'>
                                    <h2>{guideline.heading}</h2>
                                    {this.props.guidelines?.Items.length == 2 && this.hyperLink(guideline) }
                                </div>
                                {
                                    this.props.guidelines?.Items?.length == 1 && 
                                        <div className={guidelineCss.homegiudelinesrowlink}>{this.hyperLink(guideline)}</div>
                                }
                            </div>
                        
                        )
                    })
                }
            </div> </div> : null
        )
    }
}