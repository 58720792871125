import SEOCss from '../../static/css/home_page/SEOFlightRoutes.module.css';
import React from 'react'
const SEOFlightRoutes = (props) => {
    const seoSectionRendering = (array, keyPrefix) => {
        return (
            <div className={SEOCss.homeseomaincolum}>
                {
                    array?.map((el, index) => {
                        return (
                            <div className={SEOCss.homeseocolum} key={`${keyPrefix}_${index}`}>
                                {
                                    Array.isArray(el?.Links) ? <>
                                        <h3>{el.sectionHeading}</h3>
                                        <div className={SEOCss.homeseocolumsubrow}>
                                            {
                                                Array.isArray(el?.Links) && el?.Links?.map((e, i) => {
                                                    return (
                                                        <a key={`${keyPrefix}_${index}_${i}`} href={e.linkURL} target={e.newWindow}>{e.linkText}</a>
                                                    )
                                                })
                                            }
                                        </div>
                                    </> : <a href={el.WebLinkUrl} target={el.newWindowCTA}>{el.sectionHeading}</a>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    const SEOComponent = (
        !props.isMobile ? 
            <>
                {seoSectionRendering(props.seoSectionOne , 'left')}
                {seoSectionRendering(props.seoSectionTwo, 'middle')}
                {seoSectionRendering(props.seoSectionThree, 'right')}
            </> : seoSectionRendering(props.SeoFlight.seoContainer, 'right')
    )
    return (
        props.seoSectionOne.length + props.seoSectionTwo.length + props.seoSectionThree.length && props.SeoFlight != null ?
        <div className={SEOCss.homeseosection}>
            <div className={SEOCss.homeseocontainer}>
                <div className={SEOCss.homeseorow}>
                    {SEOComponent}
                </div>
            </div>
        </div> : null
    )
}
export default React.memo(SEOFlightRoutes);