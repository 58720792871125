import React from 'react';
import { Button } from "@jetprivilege/web/dist/button";
import LoginCss from '../../static/css/home_page/loginComponent.module.css';
import { Adobe } from '../../actions/adobeLayer';
import { CTEvent } from '../../actions/clevertap';
import config from '../../config/config.json';
import { loginToggleModal } from '../../actions/keycloak.service';
import { logger } from '../../services/commonFunction';
import { Link } from '@jetprivilege/web/dist/link';

export default class LoginSection extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    callLoginPopup = () => {
        try {
            
            loginToggleModal();
            //window.MvpHeader.loginToggleModal();
            Adobe.clickEvent("banner:login","home",this.props.pageInfo,this.props.loggedIn_Info)
        }
        catch(err) {
            logger("Login Error: ",err)
        }
    }

    loadPaywallPopUp = () => {
        const container = document.createElement('div');
        container.id = 'pay-wall';
        document.body.appendChild(container);

        let head = document.getElementsByTagName('head')[0];
        let paywallLink = document.createElement('link');
        paywallLink.rel = 'stylesheet';
        paywallLink.type = 'text/css';
        paywallLink.href = `${config.HeaderFooterLink.paywallCss}?ts=${Date.now()}`;
        paywallLink.media = 'all';
        head.appendChild(paywallLink);

        const script = document.createElement('script');
        script.src = `${config.HeaderFooterLink.paywallJs}?ts=${Date.now()}`;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            try {
                  window.App.initiatePaywall({
                    PaywallInstanceID: config.paywallInstanceID,
                    async eventHandler(data) {
                      if (data.event === 'ERROR') {
                        logger("Error Occured in Paywall")
                      }
                      if (data.event === 'PAYWALL_STARTED') {
                        logger("PAYWALL_STARTED")
                      }
                      if (data.event === 'PAYWALL_COMPLETE') {
                        logger("PAYWALL_COMPLETE")
                      }
                    },
                  });
            } catch(e) {
            logger("Paywall Error Occured ===>", e);
            }
        };
        
        Adobe.clickEvent("upgrade now","home",this.props.pageInfo,this.props.loggedIn_Info);
        CTEvent.callActionEvtHome("Upgrade Now"); 

    }
    render() {
        let renderComponent = "";
        let upgradeToRedTierBenefitsComponent = (
            this.props.loginComponentJSON?.ListContent?.Content?.map((el, index) => {
                if (el.PersonalizationTags.includes(this.props.userTier) && index < 4) {
                    return (
                        <div className={LoginCss.basetierfourcolum} key={`levelUpBenefits_${index}`}>
                            <div className={LoginCss.basetierimgrow}>
                                <img data-src={`${this.props.imageDomainUrl}${el.WebImage}`} alt="" alt-text="" class="lazyload"/>
                            </div>
                            <div className={LoginCss.basetierinforow}>
                                {el.SectionHeading}
                            </div>
                        </div>
                    )   
                }
            })
        )

        let loginComponent = (
            <div className={LoginCss.homeloginsection}>
                <div className={LoginCss.homelogincontainer}>
                    <div className={LoginCss.homeloginrow}>
                        <div className={LoginCss.homeloginleft}>
                            <h2>{this.props.loginComponentJSON?.staticBannerContent?.Content[0].webHeading}</h2>
                            <p>{this.props.loginComponentJSON?.staticBannerContent?.Content[0].webSubHeading}</p>
                            <div className={LoginCss.homeloginbothbuttonrow}>
                                {/* {
                                    this.props.loginComponentJSON?.staticBannerContent?.ContentLink?.map(e => {
                                        logger(e.NewBrowserTab, e.NewBrowserTab != "false", 'new page login');
                                        return <Button buttonType={e.CTAText != "Login" && "secondary"} className={e.CTAText != "Login" && LoginCss.secondarysignup} onClick={() => window.open(e.CTAUrl, e.NewBrowserTab != "false" || e.NewBrowserTab != false ? "_blank" : "_self")}>{e.CTAText}</Button>
                                    })
                                } */}
                                {/* <Button
                                     onClick={() => 
-                                        {window.open(this.props.loginComponentJSON?.staticBannerContent?.ContentLink[0].CTAUrl, this.props.loginComponentJSON?.staticBannerContent?.ContentLink[0].NewBrowserTab != "false" ? "_blank" : "_self"),
                                         Adobe.clickEvent("banner:login","home",this.props.pageInfo,this.props.loggedIn_Info)}}
                                 ></Button> */}
                                <Button
                                    onClick={() => this.callLoginPopup()}
                                >
                                    {this.props.loginComponentJSON?.staticBannerContent?.ContentLink[0].CTAText}</Button>
                                <Button 
                                    buttonType="secondary" 
                                    className={LoginCss.secondarysignup}
                                    onClick={() => 
                                        {window.open(this.props.loginComponentJSON?.staticBannerContent?.ContentLink[1].CTAUrl, this.props.loginComponentJSON?.staticBannerContent?.ContentLink[1].NewBrowserTab != "false" || this.props.loginComponentJSON?.staticBannerContent?.ContentLink[1].NewBrowserTab != false ? "_blank" : "_self");
                                        Adobe.clickEvent("banner:sign-up","home",this.props.pageInfo,this.props.loggedIn_Info);
                                    }}    
                                >
                                    {this.props.loginComponentJSON?.staticBannerContent?.ContentLink[1].CTAText}
                                </Button>

                            </div>
                        </div>
                        <div className={LoginCss.homeloginright}>
                            <img data-src={`${this.props.imageDomainUrl}${this.props.loginComponentJSON?.staticBannerContent?.Content[0]?.WebImage}`} alt="" alt-text="" class="lazyload"/>
                        </div>
                    </div>
                </div>
            </div>
        );

        let baseTierUserComponent = (
            <div className={LoginCss.basetiersection}>
                <div className={LoginCss.basetiercontainer}>
                    <div className={LoginCss.basetierrow}>
                        <div className={LoginCss.basetieheadingsection}>
                            <h1>{this.props.loginComponentJSON?.ListContent?.Headline}</h1>
                            <p>{this.props.loginComponentJSON?.ListContent?.SubHeadline}</p>
                        </div>
                        <div className={LoginCss.basetierinnercolum}>
                            {upgradeToRedTierBenefitsComponent}
                        </div>
                        <div className={LoginCss.basetierbottomrow}>
                            <span>₹ {this.props.loginComponentJSON?.ListContent?.price?.discountprice}</span> 
                            <span>₹ {this.props.loginComponentJSON?.ListContent?.price?.actualprice}</span>
                        </div>
                        <Link href="/upgrade-red-tier">
                          <Button buttonType="secondary" className={LoginCss.basetierbutton} onClick={() => {this.loadPaywallPopUp()}}>{this.props.loginComponentJSON?.ListContent?.CTAText}</Button>
                        </Link>
                    </div>
                </div>
            </div>
        );
        
        let eliteTierUserComponent = (
            <div className={LoginCss.hometiersection}>
                <div className={LoginCss.hometiecontainer}>
                    {
                        this.props.loginComponentJSON?.LinklistContent?.map((el, indx) => {
                            return (
                                <div className={LoginCss.hometierrow} key={`eliteTier_${indx}`}>
                                    <div className={LoginCss.hometierleftcolum}>
                                        <h1>{el?.Headline}</h1>
                                        <p>{el?.SubHeadline}</p>
                                        <Button className={LoginCss.hometierbutton} onClick={() => {
                                            window.open(el?.CTAUrl);
                                            Adobe.clickEvent("view all benefits","home",this.props.pageInfo,this.props.loggedIn_Info);
                                            }}>{el?.CTAText}</Button>
                                    </div>
                                    <div className={LoginCss.homeeliterightcolum}>
                                        {
                                            el.Content.map((e, i) => {
                                                if (e.PersonalizationTags.includes(this.props.userTier)) {
                                                    return (
                                                        <div className={LoginCss.hometierthreecolumcount} onClick={() => 
                                                            el.WebLinkUrl !== undefined && el.WebLinkUrl !== '' && el.WebLinkUrl !== null && window.open(el.WebLinkUrl)} key={`eliteTierBenefits_${i}`}>
                                                            <img data-src={`${this.props.imageDomainUrl}${e.WebImage}`} alt="" alt-text="" class="lazyload"/>
                                                            <p>{e.SectionHeading}</p>
                                                        </div>
                                                    )   
                                                }
                                            })
                                        }
                                    </div>
                                    <Button className={LoginCss.mobhometierbutton} onClick={() => {
                                        window.open(el?.CTAUrl);
                                        Adobe.clickEvent("view all benefits","home",this.props.pageInfo,this.props.loggedIn_Info);
                                        }}>{el?.CTAText}</Button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );

        let redTierUserComponent = (
            <div className={LoginCss.hometiersection}>
                <div className={LoginCss.hometiecontainer}>
                    {this.props.loginComponentJSON?.LinklistContent?.map((el, index) => {
                        return (
                            <div className={LoginCss.hometierrow} key={`redTier_${index}`}>
                                <div className={LoginCss.hometierleftcolum}>
                                    <h1>{el.Headline}</h1>
                                    <p>{el.SubHeadline}</p>
                                    <Button className={LoginCss.hometierbutton} onClick={() => {
                                        window.open(el.CTAUrl, '_blank');
                                        Adobe.clickEvent("view all benefits","home",this.props.pageInfo,this.props.loggedIn_Info);
                                        }}>{el.CTAText}</Button>
                                </div>
                                <div className={LoginCss.hometierrightcolum}>
                                    {
                                        el?.Content?.map((e, i) => {
                                            if (e.PersonalizationTags.includes(this.props.userTier)) {
                                                return (
                                                    <div key={`redTierBenefits_${i}`} className={LoginCss.hometiercolumcount}>
                                                            <img data-src={`${this.props.imageDomainUrl}${e.WebImage}`} alt="" alt-text="" class="lazyload"/>
                                                            <p>{e.SectionHeading}</p>
                                                    </div>
                                                )   
                                            }
                                        })
                                    }
                                </div>
                                <Button className={LoginCss.mobhometierbutton} onClick={() => {
                                    window.open(el.CTAUrl, '_blank'); 
                                   Adobe.clickEvent("view all benefits","home",this.props.pageInfo,this.props.loggedIn_Info);
                                    }}>{el.CTAText}</Button>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
        
        if (this.props.config.landingPageJSON.tierDependency.loginComponents.levelUp.includes(this.props.userTier)) {
            renderComponent = baseTierUserComponent;
        } else if (this.props.config.landingPageJSON.tierDependency.loginComponents.eliteTierBenefits.includes(this.props.userTier)) {
            renderComponent = eliteTierUserComponent;
        } else if (this.props.config.landingPageJSON.tierDependency.loginComponents.redTierBenefits.includes(this.props.userTier)) {
            renderComponent = redTierUserComponent;
        } else {
            renderComponent = loginComponent;
        }
        return renderComponent;
    }

    redirectToLogin = () => {
        try {
            loginToggleModal();
            //window.MvpHeader.loginToggleModal();
        } catch(e) {
            logger("login error:", e);
        }
    }
}