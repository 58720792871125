import config from '../config/config.json';
import { logger } from './commonFunction';
const user = {
  fetchUser: async (cookies) => {
    //logger("cookiescookies", cookies);
    if (cookies) {
      if (cookies.access_token_len && cookies.access_token_len > 0) {
        var len = cookies.access_token_len;
        var token = "";
        for (let index = 0; index < len; index++) {
          token = token + cookies["access_token" + index];
        }
        var memberid = await getMemberIds(token);
        // logger("-------2", memberid)

        return memberid
      } else {
        // logger("-------3")

        return { member_number: "", tier: "nonloggedin", isLoggedIn: false };
      }
    } else {
      // logger("-------4")
      return { member_number: "", tier: "nonloggedin", isLoggedIn: false };
    }
  }
}

async function getMemberIds(token) {
  try {
    // logger("SSO Path", config.auth0.ssofilepath + 'sso.pem');
    // logger("token", token);
    var fs = require('fs');
    var decoded = require('jsonwebtoken').verify(token, fs.readFileSync(config.auth0.ssofilepath + 'sso.pem'), { algorithms: ['RS256'] });
    if (!decoded.nickname) {
      return { member_number: "", tier: "", isLoggedIn: false };
    }
    return {
      member_number: decoded.nickname,
      tier: decoded["https://www.intermiles.com/cris_Member_Tier"],
      isLoggedIn: true,
      email: decoded["https://www.intermiles.com/cris_Member_EmailAddress"],
      first_name: decoded["https://www.intermiles.com/cris_Member_FirstName"],
      last_name: decoded["https://www.intermiles.com/cris_Member_LastName"],
      mobile_number: decoded["https://www.intermiles.com/cris_Member_MobileNumber"],
      member_title: decoded["https://www.intermiles.com/cris_Member_Title"],
      points_balance: decoded["https://www.intermiles.com/cris_Member_PointsBalance"],
      gender: decoded["https://www.intermiles.com/cris_Member_Gender"],
      country: decoded["https://www.intermiles.com/cris_Member_CountryOfResidence"],
      at_hash: decoded["https://www.intermiles.com/at_hash"] || '',
      PID: decoded["cris_Member_PersonID"]
    };
  } catch (err) {
    logger(err)
    return { member_number: "", tier: "nonloggedin", isLogged: false, error: "somting went worng" };
  }
}

export default user;