import React, { Component } from 'react';
import { ThemeProvider, InterMilesTheme } from '@jetprivilege/web/dist/theme';
import { VariantOfAutocomplete } from '@jetprivilege/web/dist/variant-of-autocomplete';
import { ModalPopup } from '@jetprivilege/web/dist/modal-popup';
import { DateRangeCalendarMobile } from '@jetprivilege/web/dist/date-range-calendar-mobile';
import { TravellerClass } from '@jetprivilege/web/dist/traveller-class';
import { InputText } from '@jetprivilege/web/dist/input-text';
import { Button } from '@jetprivilege/web/dist/button';
import { AnchoredHorizontalTabs } from '@jetprivilege/web/dist/anchored-horizontal-tabs';
import { newService } from '../../actions/middleware';
import { CTEvent } from '../../actions/clevertap';
// import { Branchio } from "../../actions/branchio";
import SearchWidgetCss from '../../static/css/home_page/searchWidget.module.css';
import Cookies from 'js-cookie';
import { localStorageUtils } from '../../actions/common';
import { Adobe } from '../../actions/adobeLayer';
import { addDays, differenceInDays } from 'date-fns';
import config from '../../config/config.json';
export default class SearchWidgetMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      to: '',
      FromAirportData: '',
      ToAirportData: '',
      departureDate: '',
      returnDate: '',
      fromAirport: null,
      prevFrom: '',
      prevFromElement: null,
      prevTo: '',
      prevToElement: null,
      toElements: null,
      toAirport: null,
      datevisiblitystart: false,
      datevisiblityreturn: false,
      returndatemodal: false,
      fromvisibility: false,
      tovisibility: false,
      startDates: {
        startDate: addDays(new Date(), 1),
        endDate: undefined,
        valid: true,
        datesInFormat: {
          format1: { startDate: '', endDate: '' },
          format2: { startDate: '', endDate: '' },
        },
      },
      prevStartDates: {},
      suggestedFromArray: this.props.suggestedArray,
      suggestedToArray: this.props.suggestedArray,
      showErrorTo: false,
      showErrorFrom: false,
      tabheaderData: config.isRedeemFlowActive
        ? [
            { title: 'Earn Miles', href: '', isDisabled: false },
            { title: 'Redeem Miles', href: '', isDisabled: false },
          ]
        : [{ title: 'Earn & Redeem Miles', href: '', isDisabled: false }],
      selectedTab: 0,
      fromElements: null,
      showSearchWidget: false,
      paxconfigData: '',
      newName: null,
      newNameTo: null,
      minonwarddate: new Date(),
      maxonwarddate: new Date(
        new Date(new Date().setYear(new Date().getFullYear() + 1)).setDate(0)
      ),
      onward_placeholder: true,
      return_placeholder: true,
      fromblankerror: false,
      toblankerror: false,
      toautofocus: false,
      fromautofocus: false,
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat'],
      paxData: [
        {
          primaryText: 'Adult',
          initialValue: 1,
          minValue: 1,
          maxValue: 9,
        },
        {
          primaryText: 'Child',
          secondaryText: '(2-12 yrs)',
          initialValue: 0,
          minValue: 0,
          maxValue: 9,
        },
        {
          primaryText: 'Infant',
          secondaryText: '(Below 2 yrs)',
          initialValue: 0,
          minValue: 0,
          maxValue: 1,
        },
      ],
      prevPaxData: [],
      travellerClass: 0,
      initialFocusOn: '',
      MobOpenCalendar: false,
      travellerClassModalOpen: false,
      fromAutoCompleteDropdownModal: false,
      toAutoCompleteDropdownModal: false,
      isRecentSearch: false,
      monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      maxBookingDays: differenceInDays(
        new Date(
          new Date(new Date().setYear(new Date().getFullYear() + 1)).setDate(0)
        ),
        new Date()
      ),
    };
    this.timer = null;
    this.myRef = React.createRef();
  }
  async componentDidMount() {
    await this.setState({
      selectedTab: window.location.search.includes('redeem') ? 1 : 0,
    });
  }
  maxreturndate = () => {
    const date1 = this.state.startDates.startDate;
    const date2 = this.state.maxonwarddate;
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  onChangeFrom = async (event) => {
    var requestedAirport = event.target.value;
    if (this.state.to == requestedAirport && requestedAirport != '') {
      this.setState({
        from: requestedAirport,
        showErrorFrom: true,
      });
    } else {
      this.setState({
        from: requestedAirport,
        showErrorFrom: false,
        showErrorTo: false,
      });
    }
    let prevData = this.state.suggestedFromArray.map((airport) => {
      return airport.ele;
    });
    let payload = { city: requestedAirport, prevData: prevData };
    // var data = await service(payload, autosuggest);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let newpayload = { query: requestedAirport, apiName: 'autosuggest' };
      newService(newpayload, 'autosuggest', '/glide/autoSuggest').then(
        (data) => {
          if (data.length) {
            const formateddata = this.formatdata(data);
            this.setState({
              suggestedFromArray: formateddata,
            });
          } else {
            this.setState({
              suggestedFromArray: [],
            });
          }
        }
      );
    }, 1000); // Adjust the throttle delay as needed (e.g., 1000ms = 1 second)
  };
  onSelectFrom = async (value) => {
    await this.state.suggestedFromArray.map((frm) => {
      if (frm.label == value) {
        this.setState((prevState) => ({
          fromElements: frm,
          fromvisibility: true,
          newName: (
            <>
              <span className={'search_city_name'}>{frm.cityName}</span>
              {/* <span className={"search_city_id"}>{frm.id}</span> */}
              {/* <span className={"search_airport_name"}>{frm.airportName}</span> */}
            </>
          ),
          onward_placeholder: true,
          return_placeholder: true,
          fromblankerror: false,
          fromAutoCompleteDropdownModal: false,
          prevFromElement: null,
          isRecentSearch: prevState.isRecentSearch
            ? !prevState.isRecentSearch
            : prevState.isRecentSearch,
        }));
      }
    });
    if (this.state?.toElements?.label == value && value != '') {
      this.setState({
        from: this.state.newName,
        showErrorFrom: true,
      });
    } else {
      this.setState({
        from: this.state.newName,
        showErrorFrom: false,
        showErrorTo: false,
      });
    }
  };
  getItemValue = (item) => {
    return item.label;
  };
  renderItems = (item, fnCall) => {
    return (
      <div
        className="homesearchresultbox"
        key={item.id}
        onClick={() => fnCall(item.label)}
      >
        {/* <p>{item.heading}</p> */}
        {!!item.heading && item.heading.length > 0 ? (
          <small>{item.heading}</small>
        ) : null}
        <span key={item.id}></span>
        {item.showArrow ? (
          <strong>
            <img alt="" alt-text="" src="static/img/searcharrow.svg" />
          </strong>
        ) : null}
        <div className="homesearchinnerdatacolum">
          <span>
            {item.cityName} {item.countryName}
          </span>
          <span className="aircodecolum">{item.id}</span>
          <p>{item.airportName}</p>
        </div>
      </div>
    );
  };
  onChangeTo = async (event) => {
    var requestedAirport = event.target.value;
    if (this.state.from == requestedAirport && requestedAirport != '') {
      this.setState({
        to: requestedAirport,
        showErrorTo: true,
      });
    } else {
      this.setState({
        to: requestedAirport,
        showErrorTo: false,
        showErrorFrom: false,
      });
    }
    let prevData = this.state.suggestedToArray.map((airport) => {
      return airport.ele;
    });
    // let payload = { city: requestedAirport, prevData: prevData };
    // var data = await service(payload, autosuggest);

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let newpayload = { query: requestedAirport, apiName: 'autosuggest' };
      newService(newpayload, 'autosuggest', '/glide/autoSuggest').then(
        (data) => {
          if (data.length) {
            const formateddata = this.formatdata(data);
            this.setState({
              suggestedToArray: [...formateddata],
            });
          } else {
            this.setState({
              suggestedToArray: [],
            });
          }
        }
      );
    }, 1000); // Adjust the throttle delay as needed (e.g., 1000ms = 1 second)
  };
  onSelectTo = async (value) => {
    await this.state.suggestedToArray.map(async (to) => {
      if (to.label == value) {
        await this.setState((prevState) => ({
          toElements: to,
          tovisibility: true,
          newNameTo: (
            <>
              <span className={'search_city_name'}>{to.cityName}</span>
              {/* <span className={"search_city_id"}>{to.id}</span> */}
              {/* <span className={"search_airport_name"}>{to.airportName}</span> */}
            </>
          ),
          onward_placeholder: true,
          return_placeholder: true,
          toblankerror: false,
          prevToElement: null,
          toAutoCompleteDropdownModal: false,
          isRecentSearch: prevState.isRecentSearch
            ? !prevState.isRecentSearch
            : prevState.isRecentSearch,
        }));
      }
    });
    if (this.state?.fromElements?.label == value && value != '') {
      this.setState({
        to: this.state.newNameTo,
        showErrorTo: true,
      });
    } else {
      this.setState({
        to: this.state.newNameTo,
        showErrorTo: false,
        showErrorFrom: false,
      });
    }
  };
  shouldItemRender = (item, value2) => {
    return item;
  };
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  onSubmitHandler = (type) => {
    const {
      toElements,
      fromElements,
      departureDate,
      returnDate,
      paxData,
      travellerClass,
    } = this.state;
    // const obj = { to, from, departureDate, returnDate, toElements, fromElements };
    let fromparam, toparam;
    let flighttype;
    let fromObj = fromElements;
    let toObj = toElements;
    fromparam = fromElements?.id;
    toparam = toElements?.id;
    flighttype = 'I';
    if (fromElements?.countryCode == 'IN' && toElements?.countryCode == 'IN') {
      flighttype = 'D';
    }
    if (this.state.showErrorTo == false && this.state.showErrorFrom == false) {
      let url = 'earn-airmiles';
      let searchType = 'UBA';
      if (this.state.selectedTab == 1) {
        url = 'redeem-airmiles';
        searchType = 'UBR';
      }
      // if (!this.state.isRecentSearch) {
      let recentSearchesObj = [];
      // Move recent searches from cookie to local storage. And Delete Previous Cookies
      let recentSearchCookies = Cookies.get('home_page_search');
      if (!!recentSearchCookies) {
        Cookies.remove('home_page_search', { path: '' });
      }
      let recentSearchData = localStorageUtils.getItem('home_page_search');
      if (!!recentSearchData) {
        recentSearchesObj = decodeURI(recentSearchData);
        recentSearchesObj = JSON.parse(decodeURI(recentSearchesObj));
      }
      let fromCity = fromObj.ele ? fromObj.ele : fromObj;
      let toCity = toObj.ele ? toObj.ele : toObj;
      fromCity['id'] = fromObj.id;
      fromCity['label'] = fromObj.label;
      fromCity['type'] = 'recent';
      toCity['id'] = toObj.id;
      toCity['label'] = toObj.label;
      toCity['type'] = 'recent';
      let departDate =
        departureDate != ''
          ? departureDate
          : this.convert(this.state.startDates.startDate);
      let retDate = returnDate ? returnDate : '';
      let travellers = {};
      paxData.forEach((element) => {
        travellers[element.primaryText.toLowerCase()] = element.initialValue;
      });
      travellers['cabin'] = ['Economy', 'Business'];
      travellers['cabinclass'] = travellerClass;
      let newSearch = {
        fromCity: fromCity,
        toCity: toCity,
        traveller: travellers,
        departureDate: departDate,
        returnDate: retDate,
        searchType: searchType,
      };
      let recentSearchIndex = recentSearchesObj.findIndex(
        (e) =>
          e.fromCity.id == newSearch.fromCity.id &&
          e.toCity.id == newSearch.toCity.id &&
          e.traveller.adult == newSearch.traveller.adult &&
          e.traveller.child == newSearch.traveller.child &&
          e.traveller.infant == newSearch.traveller.infant &&
          e.traveller.cabinclass == newSearch.traveller.cabinclass &&
          JSON.stringify(new Date(e.departureDate)).slice(1, 11) ==
            JSON.stringify(new Date(newSearch.departureDate)).slice(1, 11) &&
          JSON.stringify(new Date(e.returnDate)).slice(1, 11) ==
            JSON.stringify(new Date(newSearch.returnDate)).slice(1, 11) &&
          e.searchType == newSearch.searchType
      );
      if (recentSearchIndex == -1) {
        recentSearchesObj.push(newSearch);
        // Store only latest two searches
        if (recentSearchesObj.length > 2) recentSearchesObj.shift();
        // Set Cookie
        localStorageUtils.setItem(
          'home_page_search',
          encodeURI(JSON.stringify(recentSearchesObj))
        );
      }
      // }
      let pageInfo = {
        category: 'flights',
        pageName: `flight home${
          this.props.isInternational ? ' - international' : ''
        }`,
        subCategory1: 'home',
        subCategory2: '',
        subCategory3: this.state.selectedTab == 1 ? 'redeem' : 'earn',
        milesCategory: this.state.selectedTab == 1 ? 'Redeem' : 'Earn',
        property: 'Flights',
      };

      let modifysearchObj = {
        labelId:
          this.state.selectedTab == 1 ? 'redeem-now:fly' : 'earn-now:fly',
        departureDate: departDate ? departDate : '',
        arrivalDate: retDate ? retDate : '',
        travelDestination: toparam ? toparam : '',
        travelOrigin: fromparam ? fromparam : '',
        pax:
          parseInt(paxData[0]['initialValue']) +
          ':' +
          parseInt(paxData[1]['initialValue']) +
          ':' +
          parseInt(paxData[2]['initialValue']),
        cabinType: travellerClass == 0 ? 'Economy' : 'Business',
        journeyType: retDate ? 'Return' : 'One Way',
        flightType: flighttype == 'I' ? 'International' : 'Domestic',
        totalTravellers:
          parseInt(paxData[0]['initialValue']) +
          parseInt(paxData[1]['initialValue']) +
          parseInt(paxData[2]['initialValue']),
        pageInfo: pageInfo,
        loggedIn_Info: this.props.loggedIn_Info,
        page: 'HOME',
        searchResultType: 'original',
      };

      if (fromparam && toparam && fromparam != toparam) {
        Adobe.modifySearch(modifysearchObj);

        sessionStorage.setItem('home', true);
        CTEvent.callSearch({
          action:
            window.location.href.indexOf('/search') == -1
              ? 'Original Search'
              : 'Modify Search',
          ocode: fromObj?.id,
          dcode: toObj?.id,
          odesc: fromObj?.cityName,
          ddesc: toObj?.cityName,
          oairport: fromObj
            ? fromObj?.AIRLINE_NAME || fromObj?.airportName
            : '',
          dairport: toObj ? toObj?.airportName || toObj?.AIRLINE_NAME : '',
          rdate: retDate ? new Date(retDate) : '',
          odate: new Date(departDate),
          class: travellerClass == 1 ? 'Business' : 'Economy',
          jtype: returnDate ? 'Return' : 'One Way',
          ftype: flighttype == 'I' ? 'International' : 'Domestic',
          ostype: 'Suggested',
          dstype: 'Suggested',
          adult: paxData[0].initialValue,
          child: paxData[1].initialValue,
          infant: paxData[2].initialValue,
          cat: this.state.selectedTab == 1 ? 'Redeem' : 'Earn',
        });

        // Branchio.callSearch({
        //   action: type == "recent" ? "Recent Search" : "Original Search", ocode: fromObj?.id, dcode: toObj?.id, odesc: fromObj?.cityName, ddesc: toObj?.cityName, oairport: fromObj ? (fromObj?.AIRLINE_NAME || fromObj?.airportName) : "", dairport: toObj? (toObj?.airportName || toObj?.AIRLINE_NAME) : "", rdate: retDate ? new Date(retDate) : "", odate: new Date(departDate),
        //   class: travellerClass == 0 ? "Economy" : "Business", jtype: retDate ? 'Return' : 'One Way', ftype: flighttype == "I" ? "International" : "Domestic", ostype: "Suggested", dstype: "Suggested", adult:  paxData[0].initialValue, child:  paxData[1].initialValue, infant:  paxData[2].initialValue, cat: this.state.selectedTab == 1 ? "Redeem" : "Earn"
        // });
        setTimeout(() => {
          window.location.href =
            `${this.props.config.landingPageJSON.hostUrl}/` +
            url +
            '/search?origin=' +
            fromparam +
            '&destination=' +
            toparam +
            '&cabin=' +
            travellerClass +
            '&adults=' +
            paxData[0].initialValue +
            '&childs=' +
            paxData[1].initialValue +
            '&infants=' +
            paxData[2].initialValue +
            '&onwarddt=' +
            this.convert(departDate) +
            '&returndt=' +
            this.convert(retDate);
        }, 700);
      } else {
        if (
          !this.state?.fromElements?.label ||
          !this.state?.toElements?.label
        ) {
          if (!this.state?.fromElements?.label) {
            this.setState({
              fromblankerror: true,
            });
          }
          if (!this.state?.toElements?.label) {
            this.setState({
              toblankerror: true,
            });
          }
        }
      }
    }
  };
  convert = (str) => {
    if (!!str) {
      var date = str;
      if (typeof str == 'string') {
        date = new Date(date);
      }
      let mnth = ('0' + (date.getMonth() + 1)).slice(-2);
      let day = ('0' + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join('-');
    } else {
      return '';
    }
  };
  convertforinbox = (str, isRecent) => {
    if (!!str) {
      var date = typeof str == 'string' ? new Date(str) : str;
      if (isRecent) {
        return `${date.getDate()} ${
          this.state.monthNames[date.getMonth()]
        } ${date.getFullYear()}`;
      }
      return (
        <div>
          <div>
            {date.getDate()} {this.state.monthNames[date.getMonth()]}{' '}
            {date.getFullYear()}
          </div>
        </div>
      );
    } else {
      return '';
    }
  };
  swapOriginDestination = () => {
    this.setState((prevState) => ({
      to: typeof this.state.from == 'string' ? '' : this.state.from,
      from: typeof this.state.to == 'string' ? '' : this.state.to,
      ToAirPortData: this.state.FromAirportData,
      FromAirportData: this.state.ToAirPortData,
      fromElements: this.state.toElements,
      toElements: this.state.fromElements,
      fromvisibility: !!this.state.to,
      tovisibility: !!this.state.from,
      fromautofocus: false,
      toautofocus: false,
      isRecentSearch: prevState.isRecentSearch
        ? !prevState.isRecentSearch
        : prevState.isRecentSearch,
    }));
  };
  formatdata = (rawdata) => {
    const data = rawdata;
    for (const [key, value] of Object.entries(data)) {
      if (key == 0) {
        let suggestionType =
          data[0].type == 'recent' ? 'RECENT SEARCHS' : 'SUGGESTIONS';
        data[0].heading =
          data[0].type == 'popular' ? 'POPULAR CITIES' : suggestionType;
        if (data[0].type == 'popular') break;
      } else if (value.type == 'popular') {
        data[key].heading = 'POPULAR CITIES';
        break;
      } else if (value.type == 'recent') {
        data[key].heading = 'RECENT SEARCHES';
      }
    }
    return data;
  };
  onRecentSearch = (searchData, isDirectRedirect) => {
    /**
     * this function is used for updating state values on click of recent search items
     * author: Niket
     */
    let startDates = { ...this.state.startDates };
    startDates.startDate = new Date(searchData.departureDate);
    let departureDate = startDates.startDate;
    let returnDate = '';
    if (searchData.returnDate != '') {
      startDates.endDate = new Date(searchData.returnDate);
      returnDate = startDates.endDate;
    } else {
      startDates.endDate = undefined;
    }
    // travellerClass state update
    let paxData = [...this.state.paxData];
    // setting intial value
    paxData[0].initialValue = searchData.traveller
      ? searchData.traveller.adult
      : '1';
    paxData[1].initialValue = searchData.traveller
      ? searchData.traveller.child
      : '0';
    paxData[2].initialValue = searchData.traveller
      ? searchData.traveller.infant
      : '0';
    // setting maxValue
    // changes as part of GPD-433
    // let maxValue =
    //   9 - (+searchData.traveller.adult + +searchData.traveller.child);
    paxData[0].maxValue = 9 - +searchData.traveller.child;
    paxData[1].maxValue = 9 - +searchData.traveller.adult;
    paxData[2].maxValue = paxData[0].initialValue;
    this.setState(
      {
        from: (
          <span className={'search_city_name'}>
            {searchData.fromCity?.cityName}
          </span>
        ),
        fromElements: searchData.fromCity,
        to: (
          <span className={'search_city_name'}>
            {searchData.toCity?.cityName}
          </span>
        ),
        toElements: searchData.toCity,
        paxData,
        travellerClass: searchData.traveller
          ? searchData.traveller.cabinclass
          : '0',
        tovisibility: true,
        fromvisibility: true,
        datevisiblityreturn: false,
        datevisiblitystart: false,
        startDates,
        departureDate,
        returnDate,
        fromAutoCompleteDropdownModal: false,
        toAutoCompleteDropdownModal: false,
        isRecentSearch: true,
        showErrorFrom: false,
        showErrorTo: false,
      },
      () => {
        if (isDirectRedirect) {
          this.onSubmitHandler('recent');
        }
      }
    );
  };
  recentSearchRender = () => {
    let totalRecents = 0;
    this.props.recentSearch.map((recent) => {
      if (this.state.selectedTab == (recent.searchType == 'UBA' ? 0 : 1)) {
        totalRecents++;
      }
    });
    return this.props.recentSearch != null &&
      this.props.recentSearch.length > 0 &&
      totalRecents > 0 ? (
      <div className="recentsearchsection">
        <span>RECENT SEARCHES</span>
        <div className="recentsearchsectiondropdown">
          {this.props.recentSearch.map((recent, index) => {
            if (
              this.state.selectedTab == (recent.searchType == 'UBA' ? 0 : 1)
            ) {
              let travelClass =
                recent.traveller.cabinclass == 0 ||
                recent.traveller.cabinclass == '0'
                  ? 'Economy'
                  : 'Business';
              let month = `${new Date(recent.departureDate).getDate()} ${
                new Date(recent.returnDate).getMonth() !=
                new Date(recent.departureDate).getMonth()
                  ? this.state.monthNames[
                      new Date(recent.departureDate).getMonth()
                    ]
                  : ''
              } - ${this.convertforinbox(recent.returnDate, true)}`;
              return (
                <div
                  onClick={() => this.onRecentSearch(recent)}
                  className="maininnerrecentsearchrow"
                  key={index}
                >
                  <div className="innerrecentsearchsectiondropdown">
                    <span>
                      {recent.fromCity.cityName}
                      <b className="recentsearchcode">
                        {' '}
                        {recent.fromCity.cityCode}
                      </b>
                    </span>
                    <span>
                      <img
                        alt=""
                        alt-text=""
                        src={
                          recent.returnDate != ''
                            ? '/static/img/round_recent.svg'
                            : '/static/img/oneway_recent.svg'
                        }
                      />
                    </span>
                    <span>
                      {recent.toCity.cityName}
                      <b className="recentsearchcode">
                        {recent.toCity.cityCode}
                      </b>
                    </span>
                  </div>
                  <div className="innerrecentsearchsectiondropdown dateinnerrecentsearchsectiondropdown">
                    <span>
                      {recent.returnDate != ''
                        ? month
                        : this.convertforinbox(recent.departureDate, true)}
                    </span>
                    <span>.</span>
                    <span>{recent.traveller ? travelClass : 'Economy'}</span>
                    <span>.</span>
                    <span>
                      {recent.traveller
                        ? +recent.traveller.adult +
                          +recent.traveller.child +
                          +recent.traveller.infant
                        : '1'}
                      &nbsp; Passenger
                    </span>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    ) : null;
  };
  renderSuggestions = (array, clickfn) => {
    if (array.length > 0) {
      return array.map((children, index) => {
        return (
          <div
            className={`intermiles-homepage-search ${
              array.length === 0 ? 'recent' : 'suggest'
            }`}
            key={index}
          >
            {
              <div className="intermiles-homepage-search-inner">
                <div id="ex1-label" />
                <ul
                  role="listbox"
                  id="ex1-listbox"
                  aria-labelledby="ex1-label"
                  className={'ul-style'}
                >
                  <div className="jp-mobile-recently-searched">
                    {this.renderItems(children, clickfn)}
                  </div>
                </ul>
              </div>
            }
          </div>
        );
      });
    } else {
      return (
        <div className="serveice-unavailable">
          <span>Service unavailable. Please try again later.</span>
          <img src={'/static/img/service-failure.svg'} />
        </div>
      );
    }
  };
  manageBookingRedirection = () => {
    Adobe.clickEvent(
      'flights home:manage booking',
      'home',
      this.props.pageInfo,
      this.props.loggedIn_Info
    );
    let url = `${this.props.config.landingPageJSON.hostUrl}/managebooking`;
    if (this.props.loggedIn_Info.isLoggedIn) {
      url = this.props.config.myPurchasesURL;
    }
    window.open(url, '_blank');
  };
  render() {
    let recentSeachType =
      this.state.selectedTab == 0
        ? 'earnMilesRecentSearch'
        : 'redeemMilesRecentSearch';
    let innerSearchWidgetClsArr = [];
    innerSearchWidgetClsArr.push(SearchWidgetCss.innerserchwidgetrow);
    if (this.props[recentSeachType].length > 0) {
      innerSearchWidgetClsArr.push(SearchWidgetCss.recentSearchMargin);
    }
    let nonRecentSearchBG =
      this.state.from != '' &&
      this.state.to != '' &&
      !this.state.showErrorFrom &&
      !this.state.showErrorTo
        ? this.props.searchWidgetJSON.staticBannerContent.Content[0]
            .mobBannerImagex2
        : this.props.searchWidgetJSON.staticBannerContent.Content[0]
            .mobBannerImage;
    return (
      <ThemeProvider theme={InterMilesTheme}>
        <div className={`${InterMilesTheme} parent`}>
          {/* <div className="reshomepagebanner"> */}
          <div
            className="hometopbannersection"
            style={{
              background: `url("${this.props.imageDomainUrl}${
                this.state.isRecentSearch
                  ? this.props.searchWidgetJSON.staticBannerContent.Content[0]
                      .mobBannerImagex3
                  : nonRecentSearchBG
              }")no-repeat`,
            }}
          >
            <h1>
              {
                this.props.searchWidgetJSON.staticBannerContent.Content[0]
                  .webHeading
              }
            </h1>
            {!!this.props.searchWidgetJSON.staticBannerContent.Content[0]
              .webSubHeading && (
              <p className="bannerpara">
                {
                  this.props.searchWidgetJSON.staticBannerContent.Content[0]
                    .webSubHeading
                }
              </p>
            )}
            <div className="homebannertabsection">
              <AnchoredHorizontalTabs
                selectedTabIndex={this.state.selectedTab}
                onChange={(e, selectedTabValue) => {
                  this.setState({ selectedTab: selectedTabValue });
                  this.props.updateSearchType(selectedTabValue);
                  this.props.onSearchTypeToggle(selectedTabValue);
                }}
                tabHeader={this.state.tabheaderData}
                additionalStyle={{ backgroundColor: 'transparent' }}
              />
            </div>
            <form>
              <div className={innerSearchWidgetClsArr.join(' ')}>
                <div className={SearchWidgetCss.innerserchwidgtcontainer}>
                  <div className={SearchWidgetCss.innerserchwidgtrow}>
                    <div className="homesearchonwordmobrow">
                      <div className="modal-popup-container-mobile">
                        {/* DEPATURE DROPDOWN CODE START */}
                        {!this.state.fromAutoCompleteDropdownModal && (
                          <React.Fragment>
                            {this.state.from != '' ? (
                              <Button
                                additionalStyle={{
                                  float: 'left',
                                  zIndex: '100',
                                  backgroundColor: 'white',
                                  color: 'black',
                                  width: '100%',
                                }}
                                additionalClassName={['Z-index']}
                                buttonType=""
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    fromAutoCompleteDropdownModal: true,
                                    prevFromElement: prevState.fromElements,
                                    prevFrom: prevState.from,
                                  }))
                                }
                              >
                                <div className="jp-mobile-recently-searched">
                                  <span className={'image_label'}>
                                    <img
                                      alt=""
                                      alt-text=""
                                      src="/static/img/onwordflaghome.svg"
                                    />
                                  </span>
                                  {this.state.from}
                                </div>
                              </Button>
                            ) : (
                              <InputText
                                id={`depature_airport`}
                                onClick={() =>
                                  this.setState({
                                    fromAutoCompleteDropdownModal: true,
                                  })
                                }
                                type="text"
                                placeholder="Where From"
                                readOnly
                                leftView={
                                  <img
                                    alt=""
                                    alt-text=""
                                    src={'/static/img/onwordflaghome.svg'}
                                  />
                                }
                              />
                            )}
                          </React.Fragment>
                        )}
                        <ModalPopup
                          isOpen={this.state.fromAutoCompleteDropdownModal}
                          isMobileView={true}
                          closeButton={true}
                          onRequestClose={() => {
                            this.setState((prevState) => ({
                              from:
                                prevState.prevFrom != ''
                                  ? prevState.prevFrom
                                  : prevState.from,
                              fromElements:
                                this.state.prevFromElement != null
                                  ? prevState.prevFromElement
                                  : prevState.fromElements,
                              fromAutoCompleteDropdownModal: false,
                              fromvisibility:
                                prevState.prevFromElement != null
                                  ? true
                                  : false,
                            }));
                          }}
                          overlayClassName={'modal-popup-application'}
                        >
                          <div className="modal-popup-container-homepagemob">
                            <div className="innermodalhomepagemobrow">
                              <label>Departing From</label>
                              {this.state.fromvisibility ? (
                                <>
                                  <Button
                                    additionalStyle={{}}
                                    additionalClassName={['Z-index']}
                                    buttonType=""
                                    onClick={(e) => {
                                      this.setState({
                                        prevFrom: this.state.from,
                                        prevFromElement:
                                          this.state.fromElements,
                                        fromvisibility:
                                          !this.state.fromvisibility,
                                        fromElements: null,
                                        from: '',
                                        fromautofocus: true,
                                        FromAirportData: {},
                                      });
                                    }}
                                  >
                                    <div className="jp-mobile-recently-searched">
                                      <span className={'image_label'}>
                                        <img
                                          src="/static/img/onwordflaghome.svg"
                                          alt=""
                                          alt-text=""
                                        />
                                      </span>
                                      {this.state.from}
                                    </div>
                                  </Button>
                                </>
                              ) : null}
                              {!this.state.fromvisibility ? (
                                <VariantOfAutocomplete
                                  className="atou-testing"
                                  additionalClassName="interMiles-autocomplete"
                                  autoHighlight={true}
                                  items={this.state.suggestedFromArray}
                                  autoFocus={
                                    !this.state.fromvisibility &&
                                    this.state.fromautofocus
                                  }
                                  getItemValue={this.getItemValue}
                                  additionalStyle={{ marginBottom: '10px' }}
                                  value={this.state.from}
                                  shouldItemRender={this.shouldItemRender}
                                  renderItem={() => {
                                    return <div></div>;
                                  }}
                                  onChange={(e) => this.onChangeFrom(e)}
                                  inputIcon={'/static/img/onwordflaghome.svg'}
                                  onSelect={(e) => this.onSelectFrom(e)}
                                  onFocus={(e) => {
                                    this.setState({
                                      onward_placeholder: false,
                                    });
                                  }}
                                  onBlur={(e) => {
                                    this.setState({ onward_placeholder: true });
                                  }}
                                  inputProps={{
                                    id: 'search-flyout-mobile-input',
                                    className: `${
                                      this.state.setValue === ''
                                        ? 'jp-input-empty'
                                        : 'jp-input-value'
                                    } jp-home-desktop-input`,
                                  }}
                                />
                              ) : null}
                              {/* SUFGESTIONS */}
                              {this.state.suggestedFromArray.length > 0 &&
                                this.recentSearchRender()}
                              <div className="searchscroll">
                                {this.renderSuggestions(
                                  this.state.suggestedFromArray,
                                  this.onSelectFrom
                                )}
                              </div>
                            </div>
                          </div>
                        </ModalPopup>
                      </div>
                      <div className="homesearchautocompletedropdown">
                        {(this.state.showErrorFrom ||
                          this.state.fromblankerror) && (
                          <div className="errorbothrow">
                            <p>
                              {this.state.fromblankerror
                                ? 'Please enter the Origin'
                                : 'From and To cannot be same'}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* DEPATURE DROPDOWN CODE END */}
                    <span className="switcharrowrow homeswitchrow">
                      <div
                        className="swapOriginDestination"
                        onClick={() => {
                            if (
                              this.state.from &&
                              this.state.to &&
                              typeof this.state.from != "string" &&
                              typeof this.state.to != "string"
                            ) {
                              this.swapOriginDestination();
                            }
                          }}
                      >
                        <img
                          src="/static/img/flight-switch.svg"
                          alt=""
                          alt-text=""
                        />
                      </div>
                    </span>
                    <div className="homesearchonwordmobrow">
                      {/* ARRIVAL DROPDOWN CODE START */}
                      <div className="modal-popup-container-mobile">
                        {!this.state.toAutoCompleteDropdownModal && (
                          <React.Fragment>
                            {this.state.to != '' ? (
                              <Button
                                additionalStyle={{}}
                                additionalClassName={['Z-index']}
                                buttonType=""
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    toAutoCompleteDropdownModal: true,
                                    prevToElement: prevState.toElements,
                                    prevTo: prevState.to,
                                  }))
                                }
                              >
                                <div className="jp-mobile-recently-searched">
                                  <span className={'image_label'}>
                                    <img
                                      alt=""
                                      alt-text=""
                                      src="/static/img/returnflaghome.svg"
                                    />
                                  </span>
                                  {this.state.to}
                                </div>
                              </Button>
                            ) : (
                              <InputText
                                id={`depature_airport`}
                                onClick={() =>
                                  this.setState({
                                    toAutoCompleteDropdownModal: true,
                                  })
                                }
                                type="text"
                                placeholder="Where To"
                                readOnly
                                leftView={
                                  <img
                                    alt=""
                                    alt-text=""
                                    src={'/static/img/returnflaghome.svg'}
                                  />
                                }
                              />
                            )}
                          </React.Fragment>
                        )}
                        <ModalPopup
                          isOpen={this.state.toAutoCompleteDropdownModal}
                          isMobileView={true}
                          closeButton={true}
                          onRequestClose={() =>
                            this.setState(
                              (prevState) => ({
                                to:
                                  prevState.prevTo != ''
                                    ? prevState.prevTo
                                    : prevState.to,
                                toElements:
                                  this.state.prevToElement != null
                                    ? prevState.prevToElement
                                    : prevState.toElements,
                                toAutoCompleteDropdownModal: false,
                              }),
                              () => {
                                this.setState((prevState) => ({
                                  tovisibility:
                                    prevState.prevToElement != null
                                      ? true
                                      : false,
                                }));
                              }
                            )
                          }
                          overlayClassName={'modal-popup-application'}
                        >
                          <div className="modal-popup-container-homepagemob">
                            <div className="innermodalhomepagemobrow">
                              <label>GOING TO</label>
                              {this.state.tovisibility ? (
                                <>
                                  <Button
                                    additionalStyle={{
                                      float: 'left',
                                      zIndex: '100',
                                      backgroundColor: 'white',
                                      color: 'black',
                                      width: '100%',
                                    }}
                                    additionalClassName={['Z-index']}
                                    buttonType=""
                                    onClick={async (e) => {
                                      this.setState({
                                        prevTo: this.state.to,
                                        prevToElement: this.state.toElements,
                                        tovisibility: !this.state.tovisibility,
                                        toElements: null,
                                        to: '',
                                        toautofocus: true,
                                        ToAirPortData: {},
                                      });
                                    }}
                                  >
                                    <div className="jp-mobile-recently-searched">
                                      <span className={'image_label'}>
                                        <img
                                          src="/static/img/returnflaghome.svg"
                                          alt=""
                                          alt-text=""
                                        />
                                      </span>
                                      {this.state.to}
                                    </div>
                                  </Button>
                                </>
                              ) : null}
                              {!this.state.tovisibility ? (
                                <VariantOfAutocomplete
                                  className="atou-testing"
                                  additionalClassName="interMiles-autocomplete"
                                  autoHighlight={false}
                                  autoFocus={
                                    !this.state.tovisibility &&
                                    this.state.toautofocus
                                  }
                                  items={this.state.suggestedToArray}
                                  getItemValue={this.getItemValue}
                                  value={this.state.to}
                                  shouldItemRender={this.shouldItemRender}
                                  renderItem={() => {
                                    return <div></div>;
                                  }}
                                  onChange={(e) => this.onChangeTo(e)}
                                  inputProps={{
                                    // placeholder:'Where to?',
                                    id: 'search-flyout-mobile-input',
                                    className: `${
                                      this.state.setValue === ''
                                        ? 'jp-input-empty'
                                        : 'jp-input-value'
                                    } jp-home-desktop-input`,
                                  }}
                                  inputIcon={'/static/img/returnflaghome.svg'}
                                />
                              ) : null}
                              {/* SUFGESTIONS */}
                              {this.state.suggestedToArray.length > 0 &&
                                this.recentSearchRender()}
                              <div className="searchscroll">
                                {this.renderSuggestions(
                                  this.state.suggestedToArray,
                                  this.onSelectTo
                                )}
                              </div>
                            </div>
                          </div>
                        </ModalPopup>
                      </div>
                      <div className="homesearchautocompletedropdown">
                        {(this.state.showErrorTo ||
                          this.state.toblankerror) && (
                          <div className="errorbothrow">
                            <p>
                              {this.state.toblankerror
                                ? 'Please enter the Destination'
                                : 'From and To cannot be same'}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* ARRIVAL DROPDOWN CODE END */}
                    {this.state.from != '' &&
                      this.state.to != '' &&
                      !this.state.showErrorFrom &&
                      !this.state.showErrorTo && (
                        <>
                          <div className="homesearchdetefillmobrow">
                            <Button
                              additionalStyle={{}}
                              buttonType="primary"
                              onClick={() => {
                                this.setState((prevState) => ({
                                  initialFocusOn: 'startDate',
                                  MobOpenCalendar: true,
                                  prevStartDates: prevState.startDates,
                                  minonwarddate: new Date(),
                                }));
                              }}
                            >
                              {' '}
                              <div className="startdate-inputhome">
                                <span>
                                  <img
                                    src="/static/img/calendar.svg"
                                    alt=""
                                    alt-text=""
                                  />
                                </span>
                                {this.convertforinbox(
                                  this.state.startDates.startDate
                                )}
                              </div>
                            </Button>
                            <Button
                              additionalStyle={{}}
                              buttonType="primary"
                              onClick={() => {
                                this.setState((prevState) => ({
                                  initialFocusOn: 'endDate',
                                  MobOpenCalendar: true,
                                  prevStartDates: prevState.startDates,
                                  minonwarddate: prevState.startDates.startDate,
                                }));
                              }}
                            >
                              {' '}
                              <div className="startdate-inputhome">
                                <span>
                                  <img
                                    src="/static/img/calendar.svg"
                                    alt=""
                                    alt-text=""
                                  />
                                </span>
                                {this.state.startDates.endDate
                                  ? this.convertforinbox(
                                      this.state.startDates.endDate
                                    )
                                  : 'Return Date'}
                              </div>
                            </Button>
                            {this.state.MobOpenCalendar && (
                              <ModalPopup
                                isOpen={this.state.MobOpenCalendar}
                                isMobileView={true}
                                closeButton={true}
                                onRequestClose={() =>
                                  this.setState((prevState) => ({
                                    MobOpenCalendar: false,
                                    startDates: prevState.prevStartDates,
                                  }))
                                }
                                overlayClassName={
                                  'modal-popup-application modaldatepopup'
                                }
                              >
                                <DateRangeCalendarMobile
                                  additionalClassName={[
                                    'date-range-positionRelative',
                                  ]}
                                  onCloseButtonClick={(result) => {
                                    this.setState((prevState) => ({
                                      MobOpenCalendar: false,
                                      startDates: result,
                                      departureDate: result.startDate,
                                      returnDate: result.endDate,
                                      minonwarddate: result.startDate,
                                      isRecentSearch: prevState.isRecentSearch
                                        ? !prevState.isRecentSearch
                                        : prevState.isRecentSearch,
                                    }));
                                  }}
                                  endDatePlaceholder={
                                    'Book a round trip and save'
                                  }
                                  startDate={this.state.startDates.startDate}
                                  endDate={
                                    !this.state.startDates.endDate
                                      ? this.state.startDates.startDate
                                      : this.state.startDates.endDate
                                  }
                                  startDateLabelText="DEPARTURE DATE"
                                  endDateLabelText="RETURN DATE"
                                  mode="multiDateWithoutLoop"
                                  maxBookingDays={this.maxreturndate}
                                  initialFocusOn={this.state.initialFocusOn}
                                  minDate={this.state.minonwarddate}
                                  maxDate={this.state.maxonwarddate}
                                />
                              </ModalPopup>
                            )}
                          </div>
                          <div
                            className={
                              SearchWidgetCss.innerserchwidgtthirdcolum
                            }
                          >
                            <div
                              className={
                                SearchWidgetCss.hometravellersearchautocompletedropdown
                              }
                            >
                              {/* <label>Passengers & Class</label> */}
                              {!this.state.travellerClassModalOpen && (
                                <Button
                                  id={'travellerClass'}
                                  className={SearchWidgetCss.hometravllermob}
                                  additionalStyle={{ width: '100%' }}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      travellerClassModalOpen: true,
                                      prevPaxData: prevState.paxData,
                                    }))
                                  }
                                >
                                  <span
                                    className={
                                      SearchWidgetCss.hometravllercountmob
                                    }
                                  >
                                    <img
                                      src="/static/img/ic-singleuser.svg"
                                      alt=""
                                      alt-text=""
                                    />
                                    {`${
                                      this.state.paxData[0].initialValue +
                                      this.state.paxData[1].initialValue +
                                      this.state.paxData[2].initialValue
                                    }`}
                                  </span>
                                  <span
                                    className={
                                      SearchWidgetCss.hometravllerboxmob
                                    }
                                  >
                                    <img
                                      src="/static/img/seat-select-gray.svg"
                                      alt=""
                                      alt-text=""
                                    />
                                    {this.state.travellerClass == 0
                                      ? 'Economy'
                                      : 'Buisness'}
                                  </span>
                                </Button>
                              )}
                              <ModalPopup
                                isOpen={this.state.travellerClassModalOpen}
                                isMobileView={true}
                                closeButton={true}
                                onRequestClose={() =>
                                  this.setState((prevState) => ({
                                    travellerClassModalOpen: false,
                                    paxData: prevState.prevPaxData,
                                  }))
                                }
                                overlayClassName={
                                  'modal-popup-application modalmobtravllerrow'
                                }
                              >
                                <TravellerClass
                                  isMobile={true}
                                  passengerData={this.state.paxData}
                                  selectedClassIndex={this.state.travellerClass}
                                  onClassItemClick={(index, item) =>
                                    this.setState({ travellerClass: index })
                                  }
                                  onPassengerSelectionChange={(data) =>
                                    this.setState({ paxData: data })
                                  }
                                  onDoneButtonPress={() => {
                                    this.setState((prevState) => ({
                                      travellerClassModalOpen: false,
                                      isRecentSearch: prevState.isRecentSearch
                                        ? !prevState.isRecentSearch
                                        : prevState.isRecentSearch,
                                    }));
                                  }}
                                />
                              </ModalPopup>
                            </div>
                          </div>
                          <div className={SearchWidgetCss.homeapplysearchrow}>
                            <div className={SearchWidgetCss.searchbtnnmarg}>
                              <Button
                                buttonType="primary"
                                onClick={() => {
                                  this.onSubmitHandler('original');
                                }}
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    {this.props[recentSeachType]?.length > 0 ? (
                      <div className={SearchWidgetCss.homerecentsearchsetion}>
                        <strong>Recent Searches: </strong>
                        <span>
                          {this.props[recentSeachType]?.map((search) => {
                            let month = `${new Date(
                              search.departureDate
                            ).getDate()} ${
                              new Date(search.returnDate).getMonth() !=
                              new Date(search.departureDate).getMonth()
                                ? this.state.monthNames[
                                    new Date(search.departureDate).getMonth()
                                  ]
                                : ''
                            } - ${this.convertforinbox(
                              search.returnDate,
                              true
                            )}`;
                            return (
                              <small
                                onClick={() =>
                                  this.onRecentSearch(search, false)
                                }
                              >
                                <span>{search.fromCity.cityName}</span>
                                <span
                                  className={
                                    SearchWidgetCss.homerecentsearchdivider
                                  }
                                >
                                  {' '}
                                  -{' '}
                                </span>
                                <span>{search.toCity.cityName}</span>
                                <span
                                  className={SearchWidgetCss.homedatedivider}
                                >
                                  |
                                </span>
                                <span>
                                  {search.returnDate != ''
                                    ? month
                                    : this.convertforinbox(
                                        search.departureDate,
                                        true
                                      )}
                                </span>
                              </small>
                            );
                          })}
                        </span>
                      </div>
                    ) : null}
                    <div className={SearchWidgetCss.innerserchwidgtmanagerow}>
                      <a onClick={this.manageBookingRedirection}>
                        Manage Booking
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* </div> */}
      </ThemeProvider>
    );
  }
}
