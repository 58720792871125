import React,{useState} from "react";
// reactstrap components
// import { Breadcrumb, BreadcrumbItem } from "reactstrap";
//import config from '../../config/config.json';
import { Breadcrumb } from '@jetprivilege/web/dist/breadcrumb';
import { ThemeProvider, InterMilesTheme } from '@jetprivilege/web/dist/theme';

function BreadCrumb(props) {
const [displaytoastemsg,setToastemsg]= useState(true)

//   function onBack(){
//     if (typeof window !== "undefined" && sessionStorage.getItem("searchurl")) {
//     window.location.href=sessionStorage.getItem("searchurl");
// 		}else{
//     window.location.href="/";
//     }
// }
  return (
    <>
       {displaytoastemsg && props.toastemsg?.label? <div className={props?.pagetype=='BRP'?'brpbonusearn':'srpbonusearn'}><p>{props.toastemsg?.label} <img onClick={()=>{setToastemsg(false)}} src={'static/img/gift-close-ic.svg'} /> </p></div>:null}
      <div className="bredcrumtoprow">
      <div className="searchcontainer">
        <div className="header-brecrumb">
          <ThemeProvider theme={InterMilesTheme}>
            <div className={`${InterMilesTheme} parent`}>
              <Breadcrumb breadcrumbData={props.breadCrumbData} />
            </div>
          </ThemeProvider>
      {/* {
          !props.isSearch && 
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="/">
              Flights
              </a>
            </BreadcrumbItem>
          </Breadcrumb>
        }
      {
      props.page=="search" &&
      <Breadcrumb>
        <BreadcrumbItem active>Search</BreadcrumbItem>
      </Breadcrumb>
      }
       {
        props.page=="review" &&
      <>
        <Breadcrumb>
          <BreadcrumbItem><a onClick={onBack}>Search</a></BreadcrumbItem>
        </Breadcrumb>
        <Breadcrumb>
          <BreadcrumbItem active>Review</BreadcrumbItem>
        </Breadcrumb>
      </>
      }
      {
        props.page == "Manage Booking" &&
        <>
        {!!props.isLoggedIn && 
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => window.location.href=`${config.myPurchasesURL}`}>My Purchases</a></BreadcrumbItem>
          </Breadcrumb> }
        <Breadcrumb>
          <BreadcrumbItem active>Manage Bookings</BreadcrumbItem>
        </Breadcrumb>
        </>
      }
      {
        props.page == "Booking Details" && 
        <>
        <Breadcrumb>
        <BreadcrumbItem><a onClick={() => window.location.href="./managebooking/"}>My Purchases</a></BreadcrumbItem>
      </Breadcrumb>
      <Breadcrumb>
        <BreadcrumbItem active>Booking Details</BreadcrumbItem>
      </Breadcrumb>
      </>
      }
      {
        props.page == "Domestic Flights" && 
        <>
        <Breadcrumb>
          <BreadcrumbItem><a href={`./`}>Home</a></BreadcrumbItem>
        </Breadcrumb>
        <Breadcrumb>
          <BreadcrumbItem active>Domestic Flights</BreadcrumbItem>
        </Breadcrumb>
      </>
      }
      {
        props.page == "International Flights" && 
        <>
        <Breadcrumb>
          <BreadcrumbItem><a href={`./`}>Home</a></BreadcrumbItem>
        </Breadcrumb>
        <Breadcrumb>
          <BreadcrumbItem active>International Flights</BreadcrumbItem>
        </Breadcrumb>
      </>
      } */}
      </div>
      </div>
      </div>
    </>
  );
}

export default BreadCrumb;