import resumeBookingMobileCss from '../../static/css/resumeBookingMobile.module.css';
import {ThemeProvider,InterMilesTheme} from '@jetprivilege/web/dist/theme';
import PrimaryLoading from '../PrimaryLoading';
import config from '../../config/config.json';
const moment = require('moment');
export default function resumeBookingMobile(props){
    const cookieMinutes = (moment.duration((moment(new Date(), 'YYYY-MM-DD HH:mm')).diff(moment(new Date(props.cookieData.cookieCreationTime)))).asMinutes());
	const buttonVal = cookieMinutes > config.resumeBookingConfig.bookNowButtonTimeInMinute ? "Book Now" : "₹" + props.cookieData.payableAmount;
	let dateFormat = props.dateFormatResumeBooking(props.cookieData.onwardArrivalDate, props.cookieData.returnArrivalDate);
    return(
        <ThemeProvider theme={InterMilesTheme}>
        <div class={resumeBookingMobileCss.res_resumemain}>
            <div class={resumeBookingMobileCss.res_subdiv}>
                <div class={resumeBookingMobileCss.res_resumehead}>
                    <h1>Resume Booking ?</h1>
                </div>
                <div className={resumeBookingMobileCss.res_closeicon} onClick={props.deleteResumeBookingCookies}>
                    <img src="/static/img/resume-ic-close.svg"></img>
                </div>
                <div className={resumeBookingMobileCss.res_flightname}>
                    <span>{props.cookieData.origin}</span>
                    <span><img alt="" src={(props.cookieData.journeyType === "One Way") ? "/static/img/flight-to-horizontal.svg" :"/static/img/flight-return.svg"}></img></span>
                    <span>{props.cookieData.destination}</span>
                </div>
                <div className={resumeBookingMobileCss.res_passengerflight}>
                	<span>{props.cookieData.onwardAirlineCode} - {props.cookieData.onwardFlightNumber}</span>
					<span className={resumeBookingMobileCss.resumedot}></span>
					{
						props.cookieData.returnAirlineCode != "" && props.cookieData.returnFlightNumber != "" ?
						<>
							<span>{props.cookieData.returnAirlineCode} - {props.cookieData.returnFlightNumber} </span>
							<span className={resumeBookingMobileCss.resumedot}></span>
						</> : null
					}
					<span><img src="/static/img/calendar.svg" alt=""></img>{dateFormat} </span>
					<span className={resumeBookingMobileCss.resumedot}></span>
				    <span><img src="/static/img/ic-singleuser.svg" alt="" className={resumeBookingMobileCss.res_passengercount}></img> {props.cookieData.totalTravellers} </span>
					<span className={resumeBookingMobileCss.resumedot}></span>
					<span><img src="/static/img/seat-select-gray.svg" alt="" alt-text="" className={resumeBookingMobileCss.res_passengercount}></img> {props.cookieData.cabinType}</span>
                </div>
                <div className={resumeBookingMobileCss.resbtn}>
                    <div className="res_resumebtn">
					<PrimaryLoading 
					text={buttonVal}
					onClick={() => props.loadReviewPage(cookieMinutes, props)}>
					{buttonVal}
					</PrimaryLoading>
                    </div>
                </div>
            </div>
        </div>
        </ThemeProvider>
    )
}