import React from 'react';
import {Carousel} from '@jetprivilege/web/dist/carousel';
import featureCss from '../../static/css/home_page/features.module.css';
const Feature = (props) => {
    const FeatureRendering = (
        props?.featuresJSON?.features?.map((feature, index) => {
            return(
                <div key={`feature_${index}`} className={featureCss.featurecourosalthumbnail} onClick={() => window.open(feature.WebLinkUrl, feature.NewBrowserTab == "false" ? '_self' : '_blank')} style={{cursor:'pointer'}}>
                    <div className={featureCss.featureimgcolum}>
                        <img data-src={`${props.imageDomainUrl}${feature.WebImage}`} alt="" alt-text="" class="lazyload"/>
                    </div>
                    <div className={featureCss.featureinfocolum}>
                        <strong>{feature.SectionHeading}</strong>
                        <p>{feature?.SectionSubHeading}</p>
                    </div>
                </div>
            )
        })
    )
    const renderCarousel = (
        props.featuresJSON.featureCarousal ?
        <Carousel
            containerClass="container homefeaturecontainer"
            responsive={{
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
                partialVisibilityGutter: 0,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
            },
            }}
            partialVisible
            arrows
        >
            {FeatureRendering}
        </Carousel> : 
        <div style={{display:'flex'}} className={featureCss.lessthanfourgrid}>
            {FeatureRendering}
        </div>
    )
    let featureParentClass = ['homefeaturesection'];
    if (!!props.searchWidgetJSON.staticBannerContent.Content[0].webSubHeading) {
        featureParentClass.push('homefeaturesmargtop');
    }
    if (props[props.searchType == 'UBA' ? 'earnMilesRecentSearch' : 'redeemMilesRecentSearch'].length > 0) {
        featureParentClass.push('innertoprecentrow');
    }
    return(
        props.featuresJSON.showFeature ?
        <div className={featureParentClass.join(' ')}>
          <div className="innerhomefeaturesection">
            {
                renderCarousel
            }
        </div></div> : null
    )
}
export default React.memo(Feature);