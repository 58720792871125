import React from 'react';
import AboutCss from '../../static/css/home_page/about.module.css';
import {decodeHTML} from './commonFunction';
class About extends React.Component{
    renderHTML = (stateName, mappingArray) => {
        return this.props.readMoreState[stateName].length > 0 && mappingArray?.map((aboutEl, index) => {
            return(
                <div className={AboutCss.topaboutdescription} key={`about_${index}`}>
                    <h3>{aboutEl?.contentHeadline}</h3>
                    {
                        aboutEl.textFormat == "text" ? 
                        this.props.readMoreState[stateName][index]?.readMore ? 
                        <p>{aboutEl?.textContent?.split(" ").splice(0, this.props.readMoreState.compareValue).join(" ")}...<span onClick={() => this.props.readMoreView(index, stateName)}>read more</span></p> :
                        <p>{aboutEl?.textContent} {this.props.readMoreState[stateName][index]?.enoughText && <span onClick={() => this.props.readMoreView(index, stateName)}>read less</span>}</p> : 
                        this.props.readMoreState[stateName][index]?.readMore ? 
                        <p className={AboutCss.aboutpara}>
                            <a dangerouslySetInnerHTML={{__html: decodeHTML(aboutEl?.richTextContent, this.props.ssr).split(" ").splice(0, this.props.readMoreState.compareValue).join(" ")}}></a>
                             <b>{'...'}<span onClick={() => this.props.readMoreView(index, stateName)}>read more</span> </b>
                        </p> :
                        <p className={AboutCss.aboutpara}>
                            <a dangerouslySetInnerHTML={{__html: decodeHTML(aboutEl?.richTextContent, this.props.ssr)}}></a>
                            {this.props.readMoreState[stateName][index]?.enoughText && <span onClick={() => this.props.readMoreView(index, stateName)}>&nbsp;read less</span>}
                            {/* {'...'}<span onClick={() => this.readMoreView(index, stateName)}>read more</span>  */}
                        </p>
                        
                        // <p>{aboutEl?.richTextContent} </p>
                    }
                </div>
            )
        })
    }
    render() {
        return(
            <div className={AboutCss.homeaboutsection}>
                <div className={AboutCss.homeaboutcontainer}>
                    <div className={AboutCss.innertophomeabout}>
                        <h2>{this.props.aboutIM?.contentBoxContent[0]?.Headline}</h2>
                        {
                            this.props.isMobile ? 
                                <div className={AboutCss.homeaboutinfo}>
                                    {
                                        this.renderHTML('mobileReadMore', this.props.aboutIM?.contentBoxContent[0]?.Accordion)
                                    }
                                </div> : 
                                <>
                                    <div className={AboutCss.homeaboutinfo} >
                                        {
                                            this.renderHTML('sectionOne',  this.props.aboutFirstSection)
                                        }
                                    </div>
                                    <div className={AboutCss.homeaboutinfo} >
                                        {
                                            this.renderHTML('sectionTwo',  this.props.aboutSeconfSection)
                                        }
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default About;