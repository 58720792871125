import React from 'react';
import {Carousel} from '@jetprivilege/web/dist/carousel';
import OfferCss from '../../static/css/home_page/offerings.module.css';
import { Adobe } from '../../actions/adobeLayer';
const offers = (props) => {
    const convertforinbox = (str) => {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        if (!!str) {
          var date = typeof str == "string" ? new Date(str.replace(/-/g,"/")) : str;
          return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`
        } else {
          return false
        }
      }
    let didYouKnowClsNms = [OfferCss.default];
    if (props.offers[props.searchType]?.didYouKnowSection == 2) {
        didYouKnowClsNms.push(OfferCss.twooffer_section);
    }
    const redirectToOfferDetails = (offer, index) => {
        // Adobe code for offers click event starts
        let offerAdobeRequest = {
            eventInfo : {
                eventName:"offers and deals click" 
            },
            tabName : "",
            brickInfo: {
                brickType: offer.partnerCategory || '',
                partnerName: offer.partnerNameWeb || '',
                partnerCode: offer.partnerCodeWeb || '',
                offersDisplayed: offer.offerDesc || '',
                offersExpire: offer.offerValidity || '',
                offersPosition: index+1
            },
            offerInfo: {
                offerName: offer.offerTitle || ''
            },
            promoCode: "",
        }
        Adobe.offersClickHomepageSRP(offerAdobeRequest,props.pageInfo,props.loggedIn_Info)
        // Adobe code for offers click event ends
        //window.open(offer.offerDetailPageUrl, offer.NewBrowserTab == "true" ? '_blank': '_self');
        window.open(offer.offerDetailPageUrl, '_blank');
    }
    const OfferRendering = (
        props.offers[props.searchType]?.flightOffer?.map((offer, index) => {
            let offerValidity = convertforinbox(offer.offerValidity)
            return(
                <div className={OfferCss.offercourosalsection} key={`pffer_${index}`} style={{cursor:'pointer'}} onClick={() => redirectToOfferDetails(offer,index)}>
                    <div className={OfferCss.offercourosalthumbnail}>
                        <img data-src={`${props.imageDomainUrl}${offer.offerRegularForegroundImage}`} class="lazyload"/>
                    </div>
                    <div className={OfferCss.offercourosalthumbnailcontent}>
                        <p>{offer.partnerCategoryDesktop}</p>
                        <h3>{offer.offerTitle}</h3>
                        {offerValidity && <span>Valid upto {offerValidity}</span>}
                    </div>
                </div>
            ) 
        })
    )
    let clsNames = [OfferCss.homeoffersection];
    if (props.offers[props.searchType]?.flightOffer?.length <= 3) {
        clsNames.push(OfferCss.lessthanfourgrid)
    }
    const didYouKnow = (
        props.offers[props.searchType]?.didYouKnowSection != 0 ?
            <div className={didYouKnowClsNms.join(' ')}>
                {
                    props.didYouKnow?.DownloadAppBannerContent?.map(el => {
                        return (
                            <div className={OfferCss.twooffersinglerow}>
                                <div className={OfferCss.twooffersingleleftcolum}>
                                    <h2>{el.heading}</h2>
                                    <p dangerouslySetInnerHTML={{__html: `${el.description?.split('.')[0].replace('2x bonus Miles + Discount Vouchers', '<b>2x bonus Miles + Discount Vouchers</b>')}.`}}></p>
                                    <p>{el.description?.split('.')[1]}.</p>
                                    <span>{el.subDescription}<a href={el.ctaLink}>{el.ctaText}</a></span>
                                </div>
                                <div className={OfferCss.twooffersinglerightcolum}>
                                    <img data-src="../static/img/feewaiver.svg" class="lazyload"/>
                                </div>
                            </div>
                        )
                    })
                }
                
            </div> : null
    )
    const offersComponent = (
        props.offers[props.searchType]?.offerCarousal ?
            <Carousel
                containerClass="container homeoffercoursalcontainer"
                responsive={{
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3.32,
                    partialVisibilityGutter: 0,
                },
                mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                },
                }}
                partialVisible
                arrows
            >
                {OfferRendering}
            </Carousel> : 
            <div className={clsNames.join(' ')}>
                <div className={OfferCss.homeofferscontainer}>
                    {OfferRendering}
                    {didYouKnow}
                </div>
            </div>
    )
    return(
        props.offers[props.searchType]?.showOffers ?
        <div className='homeoffer'>
          <div className="offerinnercourosalrow">
           <h2>Flight Offers {props?.offers?.offerCarousal && <span><a target="_blank" href={props.offerDetails.url}>{props.offerDetails.title}<img data-src="../static/img/icforward.svg" class="lazyload"/></a></span>}</h2>
            {offersComponent}
        </div></div> : null
    )
}
export default React.memo(offers);