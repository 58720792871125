import WhyBookWithIMCss from '../../static/css/home_page/whyBookWithIM.module.css';
import React from 'react'
const WhyBookWithIM = (props) => {
    return(
        <div className={WhyBookWithIMCss.homebooksection}>
            <div className={WhyBookWithIMCss.homebookcontainar}>
                {
                    props.whyBookW_IM?.LinklistContent?.map((el, index) => {
                        return (
                            <div className={WhyBookWithIMCss.homebookmccrow} key={`${index}_${el}`}>
                                <div className={WhyBookWithIMCss.homebookleftcolum}>
                                    <h2>{el?.Headline}</h2>
                                    <p>{el?.SubHeadline}</p>
                                </div>
                                <div className={WhyBookWithIMCss.homebookrightcolum}>
                                    {
                                        el?.Content?.map((service, indx) => {
                                            return(
                                                <div className={WhyBookWithIMCss.whybookcolum} key={`service_${indx}`}>
                                                    <div className={WhyBookWithIMCss.whybookimgcolum}>
                                                        <img data-src={`${props.imageDomainUrl}${service.WebImage}`} alt="" alt-text="" class="lazyload"/>
                                                    </div>
                                                    <div className={WhyBookWithIMCss.whybookinfocolum}>
                                                        <p>{service.SectionHeading}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default React.memo(WhyBookWithIM);